<wp-common-popup-page>
  <wp-common-popup-page-top (closeClicked)="onClose()"> </wp-common-popup-page-top>
  <wp-common-popup-page-body>
    <!--    Header icon-->
    <div *ngIf="!hideErrorHeader" style="display: flex; justify-content: center; margin-top: 10px">
      <img src="/assets/icons/common/nonsystem-error.svg" width="59" height="42" alt="error icon" />
    </div>
    <!--    Header text-->
    <div *ngIf="!hideErrorHeader" style="font-size: 24px; font-weight: 600; margin: 18px 0 16px">
      Hmm.. something’s gone awry
    </div>
    <!--    Messages-->
    <div
      *ngIf="errorStatements.generalErrorMessage"
      style="
        margin-bottom: 21px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      "
    >
      {{errorStatements?.generalErrorMessage}}
    </div>
    <div
      *ngIf="errorStatements.specialErrorMessage"
      style="
        margin-bottom: 21px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      "
    >
      {{errorStatements?.specialErrorMessage}}
    </div>
    <div
      *ngIf="errorStatements.errorReason"
      style="
        margin-bottom: 21px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      "
    >
      {{errorStatements?.errorReason}}
    </div>
    <div
      *ngIf="errorStatements.correctiveAction"
      style="
        margin-bottom: 21px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      "
    >
      {{errorStatements?.correctiveAction}}
    </div>
    <div
      *ngIf="errorStatements.contextId"
      style="
        margin-bottom: 21px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      "
    >
      contextId: {{errorStatements?.contextId}}
    </div>
    <div class="button-wrap">
      <div *ngIf="!!error.retryFn">
        <wp-btn-ghost (clicked)="onRetry()"> Retry </wp-btn-ghost>
      </div>
      <div>
        <wp-btn-copy [textToCopy]="errorToCopy" [tooltipText]="'Copied error to clipboard'">
          Copy to clipboard
        </wp-btn-copy>
      </div>
      <div class="done-btn">
        <wp-btn-primary (clicked)="onClose()" [width]="'150px'"> {{confirmButtonText}} </wp-btn-primary>
      </div>
    </div>
  </wp-common-popup-page-body>
</wp-common-popup-page>
