<section class="select-account-form">
  <div class="instructions">Please select an account below</div>

  <wp-smart-autocomplete class="autocomplete cy-account" [model]="account"></wp-smart-autocomplete>

  <wp-smart-autocomplete
    *ngIf="subAccount"
    class="autocomplete sub-account cy-sub-account"
    [model]="subAccount"
  ></wp-smart-autocomplete>

  <wp-btn-primary class="submit-btn" (clicked)="onSubmit()" [loading]="isSubmitLoading">Go to account </wp-btn-primary>
</section>
