import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';

import { CheckboxComponent } from './checkbox/checkbox.component';
import { CheckboxControlComponent } from './checkbox-control/checkbox-control.component';
import { TriStateCheckboxComponent } from './tri-state-checkbox/tri-state-checkbox.component';

@NgModule({
  declarations: [CheckboxComponent, CheckboxControlComponent, TriStateCheckboxComponent],
  imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule],
  exports: [CheckboxComponent, CheckboxControlComponent, TriStateCheckboxComponent],
})
export class CheckboxModule {}
