import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GreyLabelComponent } from './grey-label/grey-label.component';
import { FieldValueComponent } from './field-value/field-value.component';
import { TitleOneComponent } from './title-one/title-one.component';
import { TitleTwoComponent } from './title-two/title-two.component';
import { TitleThreeComponent } from './title-three/title-three.component';
import { TitleFourComponent } from './title-four/title-four.component';
import { LinkComponent } from './link/link.component';
import { SpacedRowComponent } from './spaced-row/spaced-row.component';
import { SpacedBlockComponent } from './spaced-block/spaced-block.component';
import { FieldLabelSideValueComponent } from './field-label-side-value/field-label-side-value.component';
import { FieldLabelDownValueComponent } from './field-label-down-value/field-label-down-value.component';
import { TitleFiveComponent } from './title-five/title-five.component';
import { LinkAccordionComponent } from './link-accordion/link-accordion.component';
import { LinkBtnComponent } from './link-btn/link-btn.component';
import { LinkExternalComponent } from './link-external/link-external.component';
import { DirectivesModule } from '../directives/directives.module';
import { ArrowIconsModule } from '../icons/arrow-icons/arrow-icons.module';
import { RouterLink } from '@angular/router';
import { TextFormattedAsLinkComponent } from './text-formatted-as-link/text-formatted-as-link.component';
import { LinkDownloadComponent } from './link-download/link-download.component';

const components = [
  GreyLabelComponent,
  FieldValueComponent,
  TitleOneComponent,
  TitleTwoComponent,
  TitleThreeComponent,
  TitleFourComponent,
  TitleFiveComponent,
  LinkComponent,
  SpacedRowComponent,
  SpacedBlockComponent,
  FieldLabelSideValueComponent,
  FieldLabelDownValueComponent,
  LinkAccordionComponent,
  LinkBtnComponent,
  LinkExternalComponent,
  TextFormattedAsLinkComponent,
  LinkDownloadComponent,
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, DirectivesModule, ArrowIconsModule, RouterLink],
  exports: [...components],
})
export class TypographyModule {}
