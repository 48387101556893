import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxGroupDirective } from './checkbox-group.directive';
import { CheckboxGroupItemDirective } from './checkbox-group-item.directive';

@NgModule({
  declarations: [CheckboxGroupDirective, CheckboxGroupItemDirective],
  imports: [CommonModule],
  exports: [CheckboxGroupDirective, CheckboxGroupItemDirective],
})
export class CheckboxGroupModule {}
