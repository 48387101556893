import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../api/api.service';
import { GetContentMetadataRequest, GetContentMetadataResponse } from '../../../api/endpoints/get-content-metadata';

@Injectable()
export class ContentLandingPageApiService {
  constructor(private api: ApiService) {}

  public getContentMetadata(request: GetContentMetadataRequest): Observable<GetContentMetadataResponse> {
    return this.api.getContentMetadata(request);
  }
}
