// Production environment configuration
export const environment = {
  isMockAPI: false,
  isStoreDevToolsEnabled: false,
  isBoundariesEnabled: false,
  isBoundariesCRUDEnabled: false,
  isMonitorOwnershipAndAssignmentEnabled: false,
  isMonitorOwnershipAndAssignmentEnabledMock: false,
  isVendorEmployeeManagementEnabled: true,
  isAnnotatedRideTraceEnabled: true,
  isCampusLocationDeactivation: false,
  isRestrictionsEnabled: false,
};
