import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../api/api.service';
import { GetProfileResponse } from '../../api/endpoints/get-profile';
import { UpdateProfileRequest, UpdateProfileResponse } from '../../api/endpoints/update-profile';

@Injectable()
export class UserProfileApiService {
  constructor(private api: ApiService) {}

  public getProfile(): Observable<GetProfileResponse> {
    return this.api.getProfile();
  }

  public updateProfile(request: UpdateProfileRequest): Observable<UpdateProfileResponse> {
    return this.api.updateProfile(request);
  }
}
