import { RideDataEffects } from './ride-data.effects';
export { RideDataEffects };
import { RideListsEffects } from './ride-list.effects';
import { RideVideoEffects } from './ride-video.effects';
import { RideDataFlashEffects } from './ride-data-flash.effects';
import { RideAnnotatedTracesEffects } from './ride-annotated-traces.effects';
export { RideListsEffects };

export const featureEffects = [
  RideDataEffects,
  RideDataFlashEffects,
  RideListsEffects,
  RideVideoEffects,
  RideAnnotatedTracesEffects,
];
