import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import * as fromApi from '../../api';

import { SetPasswordForTokenRequest, SetPasswordForTokenResponse } from 'src/app/api/endpoints/password-reset';
import { SessionUser } from '../store/types';
import {
  AuthenticationProvider,
  GetAuthenticationProviderRequest,
  GetAuthenticationProviderResponse,
} from '../../api/endpoints/get-authentication-provider';
import { User } from 'firebase/auth';
import { EmployeeImpersonationSessionDetails } from '@rootTypes/entities/auth';
import { GetAccountResponse, SelectedAccount } from '../../api/endpoints/account';
import { PortalPermissionItem } from '@rootTypes';
import { ApiPortalPermissionItem } from '@apiEntities/api-portal-permission-item';
import { SessionStorageService } from '../../core/services';
import { Actions, ofType } from '@ngrx/effects';
import { loginWithPasswordRequested } from '../store';

const createMockAccountForUserRole = (userRole: string): GetAccountResponse => {
  let account: SelectedAccount;
  if (userRole === 'admin') {
    account = {
      accountId: 'adminA',
      name: 'Admin Account',
      isAccountRequired: false,
      isSubAccountsRequired: false,
      timezone: 'America/Los_Angeles',
    };
  } else if (userRole === 'vendor') {
    account = {
      accountId: 'vendorA',
      subAccountIds: ['yardAA'],
      name: 'Zum Testing',
      isAccountRequired: false,
      isSubAccountsRequired: false,
      timezone: 'America/Los_Angeles',
    };
  } else {
    account = {
      accountId: 'districtA',
      name: 'Costco District',
      isAccountRequired: false,
      isSubAccountsRequired: false,
      timezone: 'America/Los_Angeles',
    };
  }

  const permissions: ApiPortalPermissionItem[] = [
    PortalPermissionItem.ACCOUNTING_TRANSACTION_ITEM_STATUS_EDIT,
    PortalPermissionItem.ACCOUNTING_TRANSACTION_PRICING_EDIT,
    PortalPermissionItem.ACCOUNTING_TRANSACTION_REFRESH_EDIT,
    PortalPermissionItem.ACCOUNTING_TRANSACTION_REPORT_EXECUTE,
    PortalPermissionItem.ACCOUNTING_TRANSACTION_STATUS_EDIT,
    PortalPermissionItem.ACCOUNTING_TRANSACTION_VIEW,
    PortalPermissionItem.ACTIVITY_BULK_UPLOAD_APPROVE_EDIT,
    PortalPermissionItem.ACTIVITY_BULK_UPLOAD_EDIT,
    PortalPermissionItem.ACTIVITY_CHR_SYNC,
    PortalPermissionItem.CAMPUS_EDIT,
    PortalPermissionItem.CAMPUS_VIEW,
    PortalPermissionItem.CHARTER_PORTAL_TRIP_INVOICE_HISTORY_VIEW,
    PortalPermissionItem.CHARTER_TRIP_BOOKING_AMOUNT_VIEW,
    PortalPermissionItem.CHARTER_TRIP_BOOKING_EDIT,
    PortalPermissionItem.CHARTER_TRIP_DETAILS_VIEW,
    PortalPermissionItem.CHARTER_TRIP_EXPORT_EDIT,
    PortalPermissionItem.CHARTER_TRIP_INVOICE_EDIT,
    PortalPermissionItem.CHARTER_TRIP_PRICING_VIEW,
    PortalPermissionItem.CHARTER_TRIP_RIDE_VIEW,
    PortalPermissionItem.CHARTER_TRIP_STATUS_EDIT,
    PortalPermissionItem.CHARTER_TRIP_STATUS_LOG_VIEW,
    PortalPermissionItem.CHARTER_TRIP_VIEW,
    PortalPermissionItem.CHARTER_VENDOR_ASSIGNMENT_EDIT,
    PortalPermissionItem.DASHBOARD_VIEW,
    PortalPermissionItem.DISPATCH_CONTROL_CENTER_VIEW,
    PortalPermissionItem.DISTRICT_EDIT,
    PortalPermissionItem.DISTRICT_PROGRAM_EDIT,
    PortalPermissionItem.DISTRICT_VIEW,
    PortalPermissionItem.DRIVER_BUS_REPORT_APPROVE_EDIT,
    PortalPermissionItem.DRIVER_BUS_REPORT_APPROVE_VERIFY,
    PortalPermissionItem.DRIVER_BUS_REPORT_EDIT,
    PortalPermissionItem.DRIVER_BUS_REPORT_RAW_OCR_EDIT,
    PortalPermissionItem.DRIVER_BUS_REPORT_VIEW,
    PortalPermissionItem.DRIVER_DOCUMENT_EDIT,
    PortalPermissionItem.DRIVER_DOCUMENT_EXPIRATION_VIEW,
    PortalPermissionItem.DRIVER_DOCUMENT_VIEW,
    PortalPermissionItem.DRIVER_EDIT,
    PortalPermissionItem.DRIVER_LOGIN_EDIT,
    PortalPermissionItem.DRIVER_STATUS_EDIT,
    PortalPermissionItem.DRIVER_VIEW,
    PortalPermissionItem.END_OF_DAY_INSPECTION_VIEW,
    PortalPermissionItem.END_OF_RIDE_INSPECTION_VIEW,
    PortalPermissionItem.FIREBASE_CACHE_EDIT,
    PortalPermissionItem.GEOTAB_DASHBOARD_VIEW,
    PortalPermissionItem.INTER_YARD_MANAGEMENT_EDIT,
    PortalPermissionItem.MANUAL_RIDE_TAG_EDIT,
    PortalPermissionItem.MONITOR_EDIT,
    PortalPermissionItem.MONITOR_VIEW,
    PortalPermissionItem.PARENT_ROUTE_NOTIFICATION_MAILERS,
    PortalPermissionItem.PARENT_ROUTE_NOTIFICATION_SEND,
    PortalPermissionItem.PUBLISH_EXPLORER_ROUTE,
    PortalPermissionItem.REPORT_STUDENT_RFID_EXECUTE,
    PortalPermissionItem.REPORT_VIEW,
    PortalPermissionItem.RIDE_ASSIGNMENT_EDIT,
    PortalPermissionItem.RIDE_BULK_NOTIFICATIONS,
    PortalPermissionItem.RIDE_VENDOR_ASSIGNMENT_HISTORY_VIEW,
    PortalPermissionItem.RIDE_VIEW,
    PortalPermissionItem.ROUTE_EXPLORER_IMPORT_PDF_EDIT,
    PortalPermissionItem.ROUTE_EXPLORER_VIEW,
    PortalPermissionItem.ROUTE_EXPLORER_EDIT,
    PortalPermissionItem.ROUTE_EXPLORER_VIOLATION_EDIT,
    PortalPermissionItem.ROUTE_EXPLORER_VIOLATION_VIEW,
    PortalPermissionItem.ROUTE_GROUP_APPROVE_EDIT,
    PortalPermissionItem.ROUTE_GROUP_CHANGE_EDIT,
    PortalPermissionItem.ROUTE_GROUP_CREATE_EDIT,
    PortalPermissionItem.ROUTE_GROUP_VIEW,
    PortalPermissionItem.ROUTE_MOCK_RIDE_CREATE,
    PortalPermissionItem.ROUTE_SHEET_PRINT,
    PortalPermissionItem.ROUTE_CALENDAR_EDIT,
    PortalPermissionItem.ROUTE_VIEW,
    PortalPermissionItem.SCHOOL_EMPLOYEE_EDIT,
    PortalPermissionItem.SCHOOL_EMPLOYEE_PERMISSION_EDIT,
    PortalPermissionItem.SCHOOL_EMPLOYEE_VIEW,
    PortalPermissionItem.SCHOOL_SUPPORT_IMPERSONATION_EXECUTE,
    PortalPermissionItem.STANDBY_DRIVER_EDIT,
    PortalPermissionItem.STANDBY_DRIVER_VIEW,
    PortalPermissionItem.STUDENT_ASSIGN_VENDOR_EDIT,
    PortalPermissionItem.STUDENT_CHANGE_REQUEST_SELF_REQUEST_VIEW,
    PortalPermissionItem.STUDENT_EDIT,
    PortalPermissionItem.STUDENT_PARENT_ACCOUNT_ADMIN_EDIT,
    PortalPermissionItem.STUDENT_PARENT_ACCOUNT_EDIT,
    PortalPermissionItem.STUDENT_PARENT_ACCOUNT_VIEW,
    PortalPermissionItem.STUDENT_RFID_DISTRICT_CONFIG_VIEW,
    PortalPermissionItem.STUDENT_RFID_DOWNLOAD_EDIT,
    PortalPermissionItem.STUDENT_RFID_ORDER_EDIT,
    PortalPermissionItem.STUDENT_RFID_ORDER_VIEW,
    PortalPermissionItem.STUDENT_RFID_WORKQUEUE_EDIT,
    PortalPermissionItem.STUDENT_VIEW,
    PortalPermissionItem.TIMESHEET_ENTRY_EDIT,
    PortalPermissionItem.TIMESHEET_ENTRY_VIEW,
    PortalPermissionItem.TRANSPORTATION_REGISTRATION_BUS_LOOKUP_VIEW,
    PortalPermissionItem.TRANSPORTATION_REGISTRATION_EDIT,
    PortalPermissionItem.TRANSPORTATION_REGISTRATION_VIEW,
    PortalPermissionItem.USER_PERMISSION_EDIT,
    PortalPermissionItem.VEHICLE_EDIT,
    PortalPermissionItem.VEHICLE_INSPECTION_ASSESSMENT_EDIT,
    PortalPermissionItem.VEHICLE_INSPECTION_MECHANIC_ASSIGNMENT_EDIT,
    PortalPermissionItem.VEHICLE_INSPECTION_VIEW,
    PortalPermissionItem.VEHICLE_VIEW,
    PortalPermissionItem.VENDOR_EMPLOYEE_EDIT,
    PortalPermissionItem.VENDOR_EMPLOYEE_PERMISSION_EDIT,
    PortalPermissionItem.VENDOR_EMPLOYEE_VIEW,
    PortalPermissionItem.VENDOR_SUPPORT_IMPERSONATION_EXECUTE,
    PortalPermissionItem.VENDOR_VIEW,
    PortalPermissionItem.WORK_QUEUE_VIEW,
    PortalPermissionItem.YARD_VIEW,
    PortalPermissionItem.PARKOUT_EDIT,
    PortalPermissionItem.PARKOUT_VIEW,
    PortalPermissionItem.PARKOUT_EVENT_APPROVE_EDIT,
    PortalPermissionItem.PARKOUT_EVENT_VIEW,
    PortalPermissionItem.DRIVER_COMMUNICATION_VIEW,
    PortalPermissionItem.DRIVER_COMMUNICATION_EDIT,
    PortalPermissionItem.DRIVER_COMMUNICATION_APPROVE_EDIT,
    PortalPermissionItem.DRIVER_ATTENDANCE_VIEW,
    PortalPermissionItem.DRIVER_ATTENDANCE_EDIT,
    PortalPermissionItem.DISTRICT_BULK_NOTIFICATIONS,
    PortalPermissionItem.RIDE_ATTENDANCE_EDIT,
    PortalPermissionItem.SSO_CONFIG_EDIT,
  ];

  const result: GetAccountResponse = {
    account,
    permissions,
  };
  if (userRole === 'vendor') {
    result.vendor = {
      vendorId: 'vendorA',
      yardId: 'yardAA',
      vendorEmployeeId: 'vendorEmployeeA',
    };
  } else if (userRole === 'enterprise') {
    result.district = {
      districtId: 'districtA',
      campusIds: ['campusAA'],
      schoolEmployeeId: 'schoolEmployeeA',
    };
  }

  return result;
};

@Injectable()
export class AuthApiMockService {
  private userId = 'uid';
  private accountInStorageKey = `mockAccount/${wpEnvironment.userRole}`;

  constructor(
    private storage: SessionStorageService,
    private actions$: Actions,
  ) {
    this.actions$.pipe(ofType(loginWithPasswordRequested)).subscribe(() => {
      this.storage.remove(this.accountInStorageKey);
    });
  }

  public signInWithEmailAndPassword(email: string, password: string): Observable<SessionUser> {
    return of({ userId: this.userId });
  }

  public signInWithToken(token: string): Observable<SessionUser> {
    return of({ userId: this.userId });
  }

  public signInWithGoogle(ssoUrl: string, relayState?: string): void {}

  public checkAuth(): Observable<SessionUser | null> {
    return of({ userId: this.userId });
  }

  public signOut(): Observable<void> {
    return of(undefined);
  }

  public signOutFromFirebase(): Observable<void> {
    return of(undefined);
  }

  public getAccount(): Observable<fromApi.account.GetAccountResponse> {
    if (wpEnvironment.userRole === 'admin') {
      return of(createMockAccountForUserRole('admin'));
    }

    const cached: GetAccountResponse = this.storage.get(this.accountInStorageKey, true);
    if (cached) {
      return of(cached);
    }

    let res: GetAccountResponse;
    if (wpEnvironment.userRole === 'vendor') {
      res = {
        account: {
          isAccountRequired: true,
          isSubAccountsRequired: true,
          timezone: 'America/Los_Angeles',
        },
        permissions: [],
      };
    } else if (wpEnvironment.userRole === 'enterprise') {
      res = {
        account: {
          isAccountRequired: true,
          isSubAccountsRequired: false,
          timezone: 'America/Los_Angeles',
        },
        permissions: [],
      };
    }

    return of(res);
  }

  public getAccounts(accountsPreset?: string): Observable<fromApi.account.GetAccountsResponse> {
    if (wpEnvironment.userRole === 'vendor') {
      return of({
        accounts: [
          {
            id: 'vendorA',
            name: 'Zum Testing',
            subAccounts: [
              {
                id: 'yardAA',
                name: 'Mountain View',
              },
              {
                id: 'yardAB',
                name: 'LA Central',
              },
            ],
          },
          {
            id: 'vendorB',
            name: 'Yaroslav Vendor Company',
            subAccounts: [
              {
                id: 'yardBA',
                name: 'Default',
              },
            ],
          },
        ],
      });
    }

    if (wpEnvironment.userRole === 'enterprise') {
      return of({
        accounts: [
          {
            id: 'districtA',
            name: 'Costco District',
          },
          {
            id: 'districtB',
            name: 'San Francisco Unified Charter (SFUSD)',
          },
        ],
      });
    }

    return of({
      accounts: [],
    });
  }

  public setAccount(request: fromApi.account.SetAccountRequest): Observable<fromApi.account.SetAccountResponse> {
    const account = createMockAccountForUserRole(wpEnvironment.userRole);
    this.storage.set(this.accountInStorageKey, account);
    return of(account as fromApi.account.SetAccountResponse);
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return from(undefined);
  }

  public sendPasswordResetEmail(email: string): Observable<void> {
    return of(undefined);
  }

  public setPasswordForToken(request: SetPasswordForTokenRequest): Observable<SetPasswordForTokenResponse> {
    return of(undefined);
  }

  public checkFirebaseAuth(): Observable<User | null> {
    return of({ uid: this.userId } as User);
  }

  public getAuthenticationProvider(
    request: GetAuthenticationProviderRequest,
  ): Observable<GetAuthenticationProviderResponse> {
    return of({
      provider: AuthenticationProvider.PASSWORD,
    });
  }

  public getEmployeeImpersonationSessionDetails(): Observable<EmployeeImpersonationSessionDetails | null> {
    return of(null);
  }
}
