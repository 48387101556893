import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Observable, switchMap } from 'rxjs';

import { EntityState, WebError } from '@rootTypes';
import { GetContentMetadataRequest, GetContentMetadataResponse } from '../../api/endpoints/get-content-metadata';
import { ContentLandingPageApiService } from './utils/content-landing-page-api.service';

interface State {
  contentMetadata: EntityState<GetContentMetadataResponse>;
}

@Injectable()
export class ContentLandingPageStore extends ComponentStore<State> {
  public isContentMetadataLoading$ = this.select((state) => state.contentMetadata.isLoading);
  public contentMetadataError$ = this.select((state) => state.contentMetadata.error);
  public contentMetadata$ = this.select((state) => state.contentMetadata.entity);

  public loadContentMetadata = this.effect((request$: Observable<GetContentMetadataRequest>) => {
    return request$.pipe(
      switchMap((request) => {
        this.patchState({
          contentMetadata: { isLoading: true },
        });
        return this.api.getContentMetadata(request).pipe(
          tapResponse(
            (res) => {
              this.patchState({
                contentMetadata: {
                  isLoading: false,
                  entity: res,
                },
              });
            },
            (originalError: WebError) => {
              this.patchState({
                contentMetadata: {
                  isLoading: false,
                  error: {
                    text:
                      typeof originalError?.data === 'string' ? originalError.data : 'Failed to load content metadata',
                    originalError,
                  },
                },
              });
            },
          ),
        );
      }),
    );
  });

  constructor(private api: ContentLandingPageApiService) {
    super({
      contentMetadata: { isLoading: false },
    });
  }
}
