import { DriverBusReportStatus } from '@apiEntities/driver-bus-reports/driver-bus-report-status';
import { VehicleCondition } from '@apiEntities/inspections/end-of-day-inspections';
import { InvoicingProductLine } from '@apiEntities/invoicing/product-line';
import { TransactionAggregatedStatus } from '@apiEntities/invoicing/transaction-aggregated-status';
import { TransactionIssueGroup } from '@apiEntities/invoicing/transaction-issue-group';
import { ActivityStatus } from '@apiEntities/timesheet-entries';
import { CharterVendorAssignmentStatus, TripStatus, TripType } from '@apiEntities/trips';
import { TripPaymentStatus } from '@rootTypes/entities/trips/trip-payment-status';
import {
  ApiStudentStatus,
  DriverDocumentEntityFilterStatus,
  StudentChangeRequestStatus,
  StudentRfidRequestType,
  StudentRfidStatus,
  VehicleInspectionDriverReviewStatus,
  VehicleInspectionMechanicAssessmentStatus,
} from 'src/app/api/entities';
import { YYYYMMDDString } from '../common';

export enum EntityFilterRequestType {
  DRIVER = 'driver',
  DRIVER_BUS_REPORT = 'driver_bus_report',
  CHARTER_TRIPS = 'charter_trip',
  PARENT = 'parent',
  STUDENT = 'rider',
  DISTRICT = 'zum_enterprise',
  CAMPUS = 'campus',
  SCHOOL = 'school',
  VEHICLE = 'vehicle',
  VENDOR = 'vendor',
  YARD = 'yard',
  RIDE = 'ride',
  VENDOR_EMPLOYEE = 'vendor_employee',
  SCHOOL_EMPLOYEE = 'school_employee',
  DRIVER_DOCUMENT = 'driver_document',
  STUDENT_CHANGE_REQUEST = 'student_change_request',
  TRANSPORTATION_REGISTRATION = 'transportation_registration',
  ACTIVITY_TIME_ENTRY = 'activity_time_entry',
  ROUTE_GROUP_CHANGE_REQUEST = 'route_group_change_request',
  ROUTE_GROUP = 'route_group',
  USER_ACCESS_POLICY = 'user_access_policy',
  FINANCE_TRANSACTION = 'finance_transaction',
  END_OF_RIDE_INSPECTION = 'end_of_ride_inspection',
  END_OF_DAY_INSPECTION = 'end_of_day_inspection',
  STUDENT_RFID_ORDER = 'student_rfid_order',
  PARKOUT_LOCATION = 'parkout_location',
  PARKOUT_LOCATION_EVENT = 'parkout_location_event',
  PARKOUT_LOCATION_DRIVER_AUTHORIZATION = 'parkout_location_driver_authorization',
  DRIVER_COMMUNICATION = 'driver_communication',
  PARKOUT_LOCATION_EVENT_BY_DRIVER = 'parkout_location_event_by_driver',
}

export interface EntityFilterOptions {
  districtIds?: string[];
  campusIds?: string[];
  operatorIds?: string[]; // use for multi account management feature for filtering by accounts
  parentIds?: string[];
  driverIds?: string[];
  districtProgramIds?: string[];
  studentIds?: string[];
  vendorIds?: string[];
  vehicleIds?: string[];
  yardIds?: string[];
  vendorEmployeeIds?: string[];
  schoolEmployeeIds?: string[];
  zumEmployeeIds?: string[];
  rideIds?: string[];
  transportationRegistrationProgramIds?: string[];
  parkoutLocationIds?: string[];

  isSkipSelectedSubAccountFiltering?: boolean; // multi account management options

  extraFilters?:
    | BasicExtraFilters
    | CharterTripExtraFilters
    | InvoicingExtraFilters
    | EndOfRideInspectionExtraFilters
    | EndOfDayInspectionExtraFilters
    | TransportationRegistrationExtraFilters
    | StudentRfidExtraFilters
    | DriverExtraFilters
    | RouteGroupManagementExtraFilters
    | any;

  sort?: {
    [fieldName: string]: 'asc' | 'desc';
  };
}

export interface EntityFilterRequest extends EntityFilterOptions {
  type: EntityFilterRequestType;
  query?: string;
  skip?: number;
  limit?: number;
  // if not provided, return all
  status?: EntityFilterStatus;
  date?: {
    fieldName: string;
    from?: YYYYMMDDString;
    to?: YYYYMMDDString;
  };
}

export enum EntityFilterStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type BasicExtraFilters = {
  // snake case is used intentionally to support searching on BE side
  doc_status?: DriverDocumentEntityFilterStatus;
  status?: (StudentChangeRequestStatus | ActivityStatus)[];
  activity_type_id?: string[];
  activity_sub_type_id?: string[];
  // route group change requests
  changeRequestStatuses?: (StudentChangeRequestStatus | ActivityStatus)[];
  routeGroupIds?: string[];
  statuses?: ('ACTIVE' | 'DRAFT')[];
  user_role?: ('admin' | 'vendor' | 'enterprise')[];
  access_personas?: string[];
  access_permissions?: string[];
  studentStatus?: ApiStudentStatus[];
  report_status?: DriverBusReportStatus[];
  route_group_id?: string[];
  isCreatedByLoggedInUser: true;
};

export type CharterTripExtraFilters = {
  regionIds?: string[];
  tripTypes?: TripType[];
  tripStatuses?: TripStatus[];
  catalogIds?: string[];
  isBookedLoggedInUserTrips?: boolean;
  tripIds?: string[];
  paymentStates?: TripPaymentStatus[];
  vendorAssignmentStatuses?: CharterVendorAssignmentStatus[];
  vendorAssignmentResponseDue?: {
    fromTimestamp?: number;
    toTimestamp?: number;
  };
  isModified?: boolean;
};

export type InvoicingExtraFilters = {
  productLines?: InvoicingProductLine[];
  issueGroups?: TransactionIssueGroup[];
  statuses?: TransactionAggregatedStatus[];
};

export type EndOfRideInspectionExtraFilters = {
  has_item_left?: true;
  has_student_left?: true;
};

export type EndOfDayInspectionExtraFilters = {
  has_item_left?: true;
  has_student_left?: true;
  vehicle_condition?: VehicleCondition[];
  mechanic_assessment_status?: VehicleInspectionMechanicAssessmentStatus[];
  driver_review_status?: VehicleInspectionDriverReviewStatus[];
};

export type TransportationRegistrationExtraFilters = {
  registrationProgramIds: string[];
  registrationStatuses?: string[];
  registrationIds?: string[];
};

export type StudentRfidExtraFilters = {
  studentRfidStatus: StudentRfidStatus;
  studentRfidOrderIds?: string[];
  studentRfidRequestType?: StudentRfidRequestType;
};

export type DriverExtraFilters = {
  driverCategories: EntityFilterDriverCategory[];
};

export type RouteGroupManagementExtraFilters = {
  upcomingChanges?: {
    timezone: string; //Get from `GetRouteGroupFilterConfigResponse`
  };
  pendingNotification?: {
    timezone: string; //Get from `GetRouteGroupFilterConfigResponse`
  };
  futureDeadheadChange?: {
    timezone: string; //Get from `GetRouteGroupFilterConfigResponse`
  };
  statuses?: ('ACTIVE' | 'INACTIVE' | 'ARCHIVED')[];
  vehicleTypes?: string[]; //Get from `GetRouteGroupFilterConfigResponse`
};

export enum EntityFilterDriverCategory {
  DRIVER = 'DRIVER',
  MONITOR = 'MONITOR',
}
