import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { GetDefaultDistrictGradesResponse } from '../endpoints/get-default-district-grades';

@Injectable()
export class DefaultDistrictGradesApiService {
  constructor(private api: ApiService) {}

  public getDefaultDistrictGrades(): Observable<GetDefaultDistrictGradesResponse> {
    return this.api.getDefaultDistrictGrades();
  }
}
