import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { map, Observable } from 'rxjs';
import * as fromTypes from '../../../types';
import * as fromActions from '../../actions/campus-details/locations-tab.actions';
import * as fromSelectors from '../../selectors/campus-details/locations-tab.selectors';
import * as fromLocDataSelectors from '../../selectors/locations-data.selectors';

@Injectable()
export class LocationsTabFacade {
  constructor(private store: Store<State>) {}

  public getLocationIds$(): Observable<string[]> {
    return this.store.select(fromSelectors.getLocationIdsForOpenCampus);
  }

  public getLocations$(): Observable<fromTypes.CampusLocation[]> {
    return this.store.select(fromSelectors.getLocationsForOpenCampus);
  }

  public getLocationsCount$(): Observable<number> {
    return this.store.select(fromSelectors.getLocationsCountForOpenCampus);
  }

  public getLocationsLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getLocationsLoadingForOpenCampus);
  }

  public getLocationsEmpty$(): Observable<boolean> {
    return this.store.select(fromSelectors.getLocationsEmptyForOpenCampus);
  }

  public getLocationsError$(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getLocationsErrorForOpenCampus);
  }

  public getLocation(locationId: string): Observable<fromTypes.CampusLocation> {
    return this.store.select(fromLocDataSelectors.getLocation(locationId));
  }

  public getIsLocationRemoving(locationId: string): Observable<boolean> {
    return this.store.select(fromLocDataSelectors.getLocationRemoving(locationId));
  }

  public getLocationsForPhotos$(): Observable<fromTypes.CampusLocation[]> {
    return this.store.select(fromSelectors.getLocationsForPhotos);
  }

  public getPhotosViewModeDisabled$(): Observable<boolean> {
    return this.store.select(fromSelectors.getIsAnyPhotosForOpenCampusLocations).pipe(map((isAny) => !isAny));
  }

  public getViewMode$(): Observable<fromTypes.CampusLocationViewMode> {
    return this.store.select(fromSelectors.getViewMode);
  }

  public getPrimaryLocationId(): Observable<string> {
    return this.store.select(fromSelectors.getPrimaryLocationIdForOpenCampus);
  }

  public getPrimaryLocationLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getPrimaryLocationLoadingForOpenCampus);
  }

  public getSelectedLocationId$(): Observable<string> {
    return this.store.select(fromSelectors.getSelectedLocationId);
  }

  public onSelectViewMode(viewMode: fromTypes.CampusLocationViewMode): void {
    this.store.dispatch(fromActions.setViewMode({ viewMode }));
  }

  public onLocationItemClicked(locationId: string): void {
    this.store.dispatch(fromActions.locationItemClicked({ locationId }));
  }

  public onDeleteLocation(locationId: string): void {
    this.store.dispatch(fromActions.deleteLocationClicked({ locationId }));
  }

  public onSetSelectedLocation(locationId: string): void {
    this.store.dispatch(fromActions.selectLocation({ locationId }));
  }

  public onShowPhotosForLocationClicked(locationId: string): void {
    this.store.dispatch(fromActions.showPhotosForLocationClicked({ locationId }));
  }

  public onSetPrimaryLocation(locationId: string): void {
    this.store.dispatch(fromActions.makeLocationPrimaryClicked({ locationId }));
  }
}
