import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const modulePrefix = 'Data';
const sectionPrefix = 'Campus Locations';

export const getCampusLocationsRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Get campus locations`,
  props<{ campusId: string; districtId: string }>(),
);

export const getCampusLocationsRequestedFromStudentDetails = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Get campus locations from student details`,
  props<{ campusId: string; districtId: string }>(),
);

export const getCampusLocationsSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Get campus locations success`,
  props<{ campusId: string; locations: fromTypes.CampusLocation[] }>(),
);

export const getCampusLocationsFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Get campus locations failed`,
  props<{ campusId: string; error: fromTypes.WpError }>(),
);

/**
 * Make location primary
 */
export const makeLocationPrimaryRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Make location primary requested`,
  props<{ locationId: string; campusId: string; districtId: string }>(),
);

export const makeLocationPrimarySuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Make location primary success`,
  props<{ locationId: string; campusId: string; districtId: string }>(),
);

export const makeLocationPrimaryFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Make location primary failed`,
  props<{ locationId: string; campusId: string; districtId: string; error: fromTypes.WpError }>(),
);

/**
 * Delete (deactivate) location
 */
export const deleteLocationRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Delete location requested`,
  props<{ locationId: string; campusId: string; districtId: string }>(),
);

export const deleteLocationSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Delete location success`,
  props<{ locationId: string; campusId: string; districtId: string }>(),
);

export const deleteLocationFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Delete location failed`,
  props<{ locationId: string; campusId: string; districtId: string; error: fromTypes.WpError }>(),
);
