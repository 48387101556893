import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { DownloadImageSizePx, getPhotoUrl, InMemoryCache } from '../../types/utils';
import { HttpResponseStatus } from './api/http-response-status';
import { SessionService } from './api/session.service';
import { BaseApiService } from './api/base-api.service';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ImageApiUtilsService } from '../endpoints/services/image-api-utils.service';
import { downloadBlob } from '@rootTypes/utils/common/dom/download-blob';

@Injectable()
export class ImageApiService {
  private cache: InMemoryCache;
  private userRole = wpEnvironment.userRole;

  constructor(
    private session: SessionService,
    private api: BaseApiService,
    private imageUtils: ImageApiUtilsService,
    private zone: NgZone,
  ) {
    this.cache = new InMemoryCache(this.zone, 120, 5);
  }

  public getImageBase64(imagePath: string, size?: DownloadImageSizePx): Promise<string> {
    const imageId = this.getPhotoId(imagePath, size);
    let imagePromise: Promise<string> = this.cache.get(imageId);
    if (!imagePromise) {
      const imageUrl = getPhotoUrl(imagePath, size);
      imagePromise = this.getDownloadImagePromise(imageUrl);
      this.cache.set(imageId, imagePromise);
    }
    return imagePromise;
  }

  public downloadRawImage(storagePath: string, feature: string): Promise<string> {
    const imageId = storagePath + feature;
    let imagePromise: Promise<string> = this.cache.get(imageId);
    if (!imagePromise) {
      imagePromise = this.api
        .postBlob('downloadRawImage', { storagePath, feature })
        .pipe(
          take(1),
          map((res) => res.data.blob),
          switchMap((blob: Blob) => {
            return this.blobToImageBase64(blob);
          }),
        )
        .toPromise();
      this.cache.set(imageId, imagePromise);
    }
    return imagePromise;
  }

  public saveRawImageToFile(storagePath: string, feature: string, filename: string): Promise<any> {
    return this.api
      .postBlob('downloadRawImage', { storagePath, feature })
      .pipe(
        take(1),
        map((res) => res.data.blob),
        switchMap((blob: Blob) => {
          return downloadBlob(blob, filename);
        }),
      )
      .toPromise();
  }

  /**
   * @return imagePath
   */
  public uploadImage(imageBase64: string): Observable<string> {
    return this.api
      .post('uploadImage', { data: imageBase64 }, `images/${this.userRole}`)
      .pipe(map((res) => res?.imagePath));
  }

  public cleanCache(): void {
    this.cache.clear();
  }

  private async getDownloadImagePromise(imageUrl: string): Promise<string> {
    let imageBuffer: ArrayBuffer;
    try {
      imageBuffer = await this.imageUtils.getImageArrayBuffer(imageUrl);
    } catch (error) {
      const errorRes = error as HttpErrorResponse;
      if (errorRes.status === HttpResponseStatus.UNAUTHORIZED) {
        try {
          imageBuffer = await this.session.refreshSessionAndRetry(() => this.imageUtils.getImageArrayBuffer(imageUrl));
        } catch (retryError) {
          console.error(retryError);
          throw retryError;
        }
      } else {
        throw errorRes;
      }
    }
    // Source: https://medium.com/front-end-weekly/fetching-images-with-the-fetch-api-fb8761ed27b2
    const imageBase64 = 'data:image/jpeg;base64,' + this.imageUtils.arrayBufferToBase64(imageBuffer);
    return imageBase64;
  }

  private getPhotoId(imagePath: string, size?: DownloadImageSizePx): string {
    let id = imagePath;
    if (size) {
      id += size.width || '';
      id += size.height || '';
    }
    return id;
  }

  private blobToImageBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        let res = reader.result as string;
        res = res.replace('data:application/octet-stream;base64,', 'data:image/jpeg;base64,');
        resolve(res);
      };
      reader.readAsDataURL(blob);
    });
  }
}
