import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentLandingPageStore } from './content-landing-page.store';
import { Observable } from 'rxjs';
import { WpError } from '@rootTypes';
import { iconPaths } from '@rootTypes/utils';
import { GetContentMetadataRequest, GetContentMetadataResponse } from '../../api/endpoints/get-content-metadata';
import { ContentLandingPageQueryParams } from './types/content-landing-page-query-params';
import { getContentMetadataParamsFromQuery } from './utils/get-content-metadata-params-from-query';
import { ContentLandingPageFileType } from './types/content-landing-page-file-type';
import { ContentLandingPageAction } from './types/content-landing-page-action';
import { ContentLandingPageApiService } from './utils/content-landing-page-api.service';

@Component({
  selector: 'wp-content-landing-page',
  templateUrl: './content-landing-page.component.html',
  styleUrl: './content-landing-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContentLandingPageApiService, ContentLandingPageStore],
})
export class ContentLandingPageComponent implements OnInit {
  public isLoading$: Observable<boolean>;
  public loadingError$: Observable<WpError>;
  public contentMetadata$: Observable<GetContentMetadataResponse>;
  public isDownload: boolean;
  public isImagePreview: boolean;

  public datasetIcon = iconPaths.DATASET_LINK;

  constructor(
    private activatedRoute: ActivatedRoute,
    private pageStore: ContentLandingPageStore,
  ) {}

  public ngOnInit(): void {
    this.isLoading$ = this.pageStore.isContentMetadataLoading$;
    this.loadingError$ = this.pageStore.contentMetadataError$;
    this.contentMetadata$ = this.pageStore.contentMetadata$;

    // example: /landing?fileType=pdf&action=download&method=get&target=downloadReport&params=filePath%3DSchool_Employees.pdf
    // example: /landing?fileType=image&action=view&method=get&target=downloadReport&params=filePath%3DSchool_Employees.jpg
    const queryParams = this.activatedRoute.snapshot.queryParams as ContentLandingPageQueryParams;

    this.isDownload = queryParams.action === ContentLandingPageAction.DOWNLOAD;
    this.isImagePreview =
      queryParams.action === ContentLandingPageAction.VIEW && queryParams.fileType === ContentLandingPageFileType.IMAGE;

    const request: GetContentMetadataRequest = {
      contentApi: queryParams.target,
      params: getContentMetadataParamsFromQuery(queryParams.params),
    };
    if (queryParams.method) {
      request.method = queryParams.method;
    }
    this.pageStore.loadContentMetadata(request);
  }
}
