import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import { VendorEmployeeDetailsTab } from '../../types';
import * as fromActions from '../actions/employee-details.actions';

export interface EmployeeDetailsState {
  employeeId: string;
  tab: fromTypes.EmployeeDetailsTabs | VendorEmployeeDetailsTab;
}

export const initialEmployeeDetailsState: EmployeeDetailsState = {
  employeeId: undefined,
  tab: undefined,
};

export const employeeDetailsReducer = createReducer<EmployeeDetailsState>(
  { ...initialEmployeeDetailsState },
  on(fromTypes.cleanUpStore, () => ({ ...initialEmployeeDetailsState })),
  on(fromActions.setEmployeeId, (state, action) => {
    const { employeeId } = action;
    const res: EmployeeDetailsState = {
      ...state,
      employeeId,
    };
    return res;
  }),
  on(fromActions.setTab, (state, action) => {
    const { tab } = action;
    const res: EmployeeDetailsState = {
      ...state,
      tab,
    };
    return res;
  }),
);
