import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'wp-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit, OnChanges {
  @Input() public isLarge = false;
  @Input() public selected: boolean;
  @Input() public disabled = false;
  @Input() public required = false;
  @Input() public requiredErrorMessage = '';
  @Input() public indeterminate = false;
  @Input() public disableAnimation = false;
  @Input() public labelVerticalAlignment: 'center' | 'top' = 'center';

  @Output() public valueChanged = new EventEmitter<boolean>();

  public isRequiredError = false;
  public control: FormControl<boolean>;

  constructor(private cdRef: ChangeDetectorRef) {}

  public onCheckboxValueChange(event: MatCheckboxChange): void {
    if (this.required) {
      this.isRequiredError = !event.checked;
    } else {
      this.isRequiredError = false;
    }
    this.valueChanged.emit(event.checked);
  }

  public validate(): boolean {
    if (!this.required) {
      this.isRequiredError = false;
      return true;
    }
    this.isRequiredError = !this.selected;
    this.cdRef.detectChanges();
    return !this.isRequiredError;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.control && changes.selected) {
      this.control.setValue(!!this.selected);
    }
    if (this.control && changes.disabled) {
      if (this.disabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  ngOnInit(): void {
    this.control = new FormControl<boolean>({ value: !!this.selected, disabled: !!this.disabled });
  }
}
