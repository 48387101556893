import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IdGenerator } from './models/id-generator';
import { X_REQ_ID } from './http-req-headers';
import { devLog } from './models/dev-log';
import { Observable, Subject } from 'rxjs';
import { SessionUser } from '@rootTypes/entities/auth';

interface ApiAccessTokenData {
  tokenExpTime: number;
  token: string;
}

interface LoginResponse {
  customToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public sessionEnd$: Observable<void>;

  // Holds pending promise while the session is initializing
  private sessionPromise: Promise<void> | undefined;

  /**
   * Holds undefined if user is not logged in.
   * Holds pending promise while Fb token is initializing.
   * Holds the resolved object after token has been initialized.
   */
  private apiAccessTokenPromise: Promise<ApiAccessTokenData> | undefined;

  private tokenMinLifeTimeMs = 5 * 1000; // safe time frame to keep using a token

  /**
   * The intent of this field is informational, just to group all the requests
   * coming from the same browser session for logging in the backend.
   */
  private _sessionId: string | undefined;

  private get sessionId(): string {
    if (!this._sessionId) {
      this._sessionId = Date.now().toString();
    }
    return this._sessionId;
  }

  private sessionEnd$$: Subject<void>;
  private isFbUserAuthenticated = false;

  constructor(
    private http: HttpClient,
    private fb: FirebaseService,
  ) {
    this.sessionEnd$$ = new Subject();
    this.sessionEnd$ = this.sessionEnd$$.asObservable();

    this.fb.onAuthStateChanged((user) => {
      const isUserAuthenticatedRecently = this.isFbUserAuthenticated;
      this.isFbUserAuthenticated = !!user;
      if (!user) {
        this.apiAccessTokenPromise = undefined;
        this._sessionId = undefined;
        if (isUserAuthenticatedRecently) {
          this.sessionEnd$$.next();
        }
      }
    });
  }

  public async loginWithCredentials(email: string, password: string): Promise<SessionUser> {
    const resp = await this.loginToAPIWithCredentials(email, password);
    const userDetail = await this.fb.signInWithLoginToken(resp.customToken);
    // await this.initializeSession('loginWithCredentials');
    return userDetail;
  }

  public async loginWithToken(token: string): Promise<SessionUser> {
    const userDetail = await this.fb.signInWithLoginToken(token);
    await this.initializeSession('loginWithToken');
    return userDetail;
  }

  /**
   * Logouts from Firebase and ZUM Backend.
   * This method always resolves.
   */
  public async logout(): Promise<void> {
    try {
      if (this.getUserId()) {
        await this.closeSession();
      }
    } finally {
      await this.logoutFromFirebase();
    }
  }

  public async refreshSessionAndRetry<T>(requestFn: () => Promise<T>): Promise<T> {
    try {
      await this.initializeSession('refreshSessionAndRetry');
    } catch (error) {
      await this.logoutFromFirebase();
      throw error;
    }
    // must not await on callback as the error from the execution of
    // callback function is not the responsibility of this function
    return requestFn();
  }

  public generateRequestId(): string {
    return `${this.sessionId}${IdGenerator.generate()}`;
  }

  // TODO (https://ridezum.atlassian.net/browse/PORTAL-36): Consider replacing with just firebase.getToken()
  public async getAPIAccessToken(): Promise<string> {
    if (!this.apiAccessTokenPromise) {
      this.apiAccessTokenPromise = this.getApiAccessTokenData();
    }
    let apiAccessToken: ApiAccessTokenData = await this.apiAccessTokenPromise;

    if (apiAccessToken.tokenExpTime < Date.now() - this.tokenMinLifeTimeMs) {
      this.apiAccessTokenPromise = this.getApiAccessTokenData();
      apiAccessToken = await this.apiAccessTokenPromise;
    }

    return apiAccessToken.token;
  }

  public getUserId(): string | null {
    return this.fb.uid();
  }

  // Private methods
  private async initializeSession(invoker?: string): Promise<void> {
    try {
      if (!this.sessionPromise) {
        this.sessionPromise = this.createSession(invoker);
      }
      return await this.sessionPromise;
    } finally {
      this.sessionPromise = undefined;
    }
  }

  private async createSession(invoker?: string): Promise<void> {
    try {
      const headers = await this.getRequestHeaders();
      const loginResponse = (await this.http
        .post(`${wpEnvironment.apiBaseUrl}/login`, { api: 'login' }, { headers, withCredentials: true })
        .toPromise()) as LoginResponse;
      await this.fb.signInWithLoginToken(loginResponse.customToken);
      devLog(`SESSION CREATED by ${invoker}`);
    } catch (originalError) {
      let err: Error;
      if (invoker === 'refreshSessionAndRetry') {
        err = new Error('Failed to refresh session');
      } else {
        err = new Error(`Failed to create session`);
      }
      console.error(err, originalError);
      throw err;
    }
  }

  private async closeSession(): Promise<void> {
    try {
      const headers = await this.getRequestHeaders();
      await this.http
        .post(`${wpEnvironment.apiBaseUrl}/logout`, { api: 'logout' }, { headers, withCredentials: true })
        .toPromise();
    } catch (errRes) {
      if (errRes.error !== 'UNAUTHORIZED') {
        const err = new Error('Failed to close session');
        console.error(err, errRes);
        throw err;
      } else {
        // already logged out, do nothing
      }
    }
  }

  private logoutFromFirebase(): Promise<void> {
    return this.fb.signOut();
  }

  private async getApiAccessTokenData(): Promise<ApiAccessTokenData> {
    const result: any = await this.fb.getIdTokenResult(true);
    return {
      token: result.token,
      tokenExpTime: new Date(result.expirationTime).getTime(),
    };
  }

  private async getRequestHeaders(): Promise<HttpHeaders> {
    const idToken = await this.fb.token(true);
    return new HttpHeaders().append('Authorization', `Bearer ${idToken}`).append(X_REQ_ID, this.generateRequestId());
  }

  private loginToAPIWithCredentials(email: string, password: string): Promise<LoginResponse> {
    const authHeaderValue = `Basic ${btoa(`${email}:${password}`)}`;
    return this.http
      .post(
        `${wpEnvironment.apiBaseUrl}/login`,
        { api: 'login' },
        {
          headers: new HttpHeaders().append('Authorization', authHeaderValue),
          withCredentials: true,
          responseType: 'json',
        },
      )
      .toPromise() as Promise<LoginResponse>;
  }
}
