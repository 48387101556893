/**
 * TODO: Flash lib should be migrated to new typescript and rxjs
 */
import { Injectable } from '@angular/core';
import { FirebaseService } from './services/firebase/firebase.service';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { catchError, map, retry, take } from 'rxjs/operators';
import * as fromApi from '.';
import {
  GetRouteIssuesByEntity,
  connect,
  disconnect,
  subscribeToRideLocation,
  subscribeToRideRouteTrace,
  subscribeToRideStatus,
  subscribeToRidesPrimary,
  subscribeToRidesSecondary,
  unsubscribeToRideLocation,
  unsubscribeToRideStatus,
  RideLocation,
  RideStatus,
  unsubscribeRouteIssues,
  GetRouteIssuesByDistrictPrograms,
  GetRouteIssuesByDistrictProgramsPayload,
  subscribeDriverBusReports,
  subscribeToFlaggedCheckpoints,
  unsubscribeToFlaggedCheckpoints,
  GetFlaggedCheckpoints,
  subscribeRouteIssuesByDistrictPrograms,
} from '@zum/flash-client';
import { GetDriverBusReportResponse } from './endpoints/get-driver-bus-report';
import { GetRouteIssuesByEntityRequest, GetRouteIssuesByEntityResponse } from './endpoints/get-route-issues-by-entity';
import { YYYYMMDDString } from './entities/common';
import { Checkpoint } from './entities/checkpoints/checkpoint';
import { WebError } from '@rootTypes';
import { VehicleLocation } from '@apiEntities/dashboard/vehicle-location';
import { GetRouteIssuesByDistrictProgramsResponse } from './endpoints/get-route-issues-by-district-programs';
import { RouteIssueEntityType } from '@apiEntities/route-explorer/route-issues';
import { subscribeToVehicleLocation, unsubscribeToVehicleLocation } from '@zum/flash-client/dist/sdk/api/vehicle';

export type ByRide<T> = {
  rideId: string;
  result: T;
};

export type GetRouteTracesResponse = fromApi.getRouteTraces.GetRouteTracesResponse;
export type RideStreamItem = {
  rideId: string;
};
// Route explorer - route issues
export type RouteIssueUpdateEvent = RouteIssueUpdateEntityEvent | RouteIssueUpdateListEvent;
export type RouteIssueUpdateEntityEvent = {
  type: 'update-entity';
  entityId: string;
  entityType: RouteIssueEntityType;
  districtProgramId: string; // safeguard against updates from other programs
  districtId: string; // safeguard against updates from other districts
  payload: GetRouteIssuesByEntityResponse;
};
export type RouteIssueUpdateListEvent = {
  type: 'update-list';
  districtPrograms: { districtProgramId: string; batchId: string }[]; // safeguard against updates from other programs
  payload: GetRouteIssuesByDistrictProgramsResponse;
};

export type FlaggedCheckpointUpdateEvent = {
  assigneeId: string;
  result: Checkpoint;
};

@Injectable()
export class FlashApiService {
  private isLogsEnabled = false;
  constructor(
    private firebaseService: FirebaseService,
    private apiService: ApiService,
  ) {}

  public connectFlashClient(): void {
    if (this.isLogsEnabled) {
      console.log('[Flash]: Connect client');
    }
    connect({
      baseUrl: wpEnvironment.apiBaseUrl,
      accessTokenIssuer: () => this.firebaseService.token(true),
      appRole: wpEnvironment.userRole,
    });
  }

  public disconnectFlashClient(): void {
    if (this.isLogsEnabled) {
      console.log('[Flash]: Disconnect client');
    }
    disconnect();
  }

  public subscribeToRidesPrimary(rideIds: string[]): Observable<ByRide<RideStreamItem>> {
    if (this.isLogsEnabled) {
      console.log('[Flash]: Subscribe rides primary', rideIds);
    }
    return subscribeToRidesPrimary<RideStreamItem>(
      rideIds,
      (rideId: string) => of({ rideId }) as any,
    ) as unknown as Observable<ByRide<RideStreamItem>>;
  }

  public subscribeToRidesSecondary(rideIds: string[]): Observable<ByRide<RideStreamItem>> {
    if (this.isLogsEnabled) {
      console.log('[Flash]: Subscribe rides secondary', rideIds);
    }
    return subscribeToRidesSecondary<RideStreamItem>(
      rideIds,
      (rideId: string) => of({ rideId }) as any,
    ) as unknown as Observable<ByRide<RideStreamItem>>;
  }

  public subscribeToDBRList(
    reportIds: string[],
  ): Observable<{ driverBusReportId: string; result: GetDriverBusReportResponse }> {
    if (this.isLogsEnabled) {
      console.log('[Flash]: Subscribe DBR list');
    }
    const dbrGetter = (request) =>
      this.apiService.getDriverBusReport({ driverBusReportId: request.driverBusReportId }).pipe(
        catchError((error) => {
          console.log('error getting dbr for update:');
          console.log(error);
          return of(null);
        }),
      );
    return subscribeDriverBusReports<GetDriverBusReportResponse>(reportIds, dbrGetter as any) as unknown as Observable<{
      driverBusReportId: string;
      result: GetDriverBusReportResponse;
    }>;
  }

  public subscribeToRideRouteTrace(rideId: string): Observable<GetRouteTracesResponse> {
    if (this.isLogsEnabled) {
      console.log('[Flash]: Subscribe route traces', rideId);
    }
    return (
      subscribeToRideRouteTrace<GetRouteTracesResponse>(
        rideId,
        this.getLastRouteTraceImpl(rideId) as any,
        this.getRouteTraceImpl() as any,
      ) as unknown as Observable<{ result: GetRouteTracesResponse }>
    ).pipe(map((item) => item.result));
  }

  public subscribeToRideLocation(rideIds: string[]): Observable<RideLocation> {
    return subscribeToRideLocation(rideIds) as unknown as Observable<RideLocation>;
  }

  public unsubscribeToRideLocation(): Promise<void> {
    return unsubscribeToRideLocation();
  }

  public subscribeToRideStatus(): Observable<RideStatus> {
    return subscribeToRideStatus() as unknown as Observable<RideStatus>;
  }

  public unsubscribeToRideStatus(): Promise<void> {
    return unsubscribeToRideStatus();
  }

  public subscribeToRouteIssues(
    districtProgramBatches: { districtProgramId: string; batchId: string }[],
  ): Observable<RouteIssueUpdateEvent> {
    const getRouteIssuesByDistrict: GetRouteIssuesByDistrictPrograms<RouteIssueUpdateListEvent> = (
      request: GetRouteIssuesByDistrictProgramsPayload,
    ) => {
      return this.apiService.getRouteIssuesByDistrictPrograms(request).pipe(
        map((payload) => {
          return {
            type: 'update-list',
            districtPrograms: request.districtPrograms,
            payload,
          } satisfies RouteIssueUpdateListEvent;
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        }),
      ) as any;
    };
    const getRouteIssuesByEntity: GetRouteIssuesByEntity<RouteIssueUpdateEntityEvent> = (request) => {
      return this.apiService.getRouteIssuesByEntity(request as GetRouteIssuesByEntityRequest).pipe(
        map((payload) => {
          return {
            type: 'update-entity',
            entityId: request.id,
            entityType: request.type,
            districtId: request.districtId,
            districtProgramId: request.districtProgramId,
            payload,
          };
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        }),
      ) as any;
    };
    return (
      subscribeRouteIssuesByDistrictPrograms(
        districtProgramBatches,
        getRouteIssuesByDistrict,
        getRouteIssuesByEntity,
      ) as unknown as Observable<{
        result: RouteIssueUpdateEvent;
      }>
    ).pipe(map(({ result }) => result));
  }

  public unsubscribeToRouteIssues(): Promise<void> {
    return unsubscribeRouteIssues();
  }

  public subscribeToFlaggedCheckpoints(
    yardIds: string[],
    date: YYYYMMDDString,
  ): Observable<FlaggedCheckpointUpdateEvent> {
    const getCheckpointFn: GetFlaggedCheckpoints<Checkpoint> = (request): any => {
      return this.apiService
        .getFlaggedCheckpoints({
          assigneeIds: [request.assigneeId],
          date,
        })
        .pipe(
          map((res) => res.assigneeCheckpoints[0]),
          retry({ count: 2, delay: 5000 }),
          catchError((error: WebError) => {
            console.error(new Error(`Failed to get checkpoint update for assigneeId: ${request.assigneeId}`), error);
            throw error;
          }),
        );
    };
    return subscribeToFlaggedCheckpoints<Checkpoint>(yardIds, date, getCheckpointFn) as any;
  }

  public unsubscribeToFlaggedCheckpoints(): Promise<void> {
    return unsubscribeToFlaggedCheckpoints();
  }

  public subscribeToVehicleLocations(): Observable<VehicleLocation> {
    return subscribeToVehicleLocation() as unknown as Observable<VehicleLocation>;
  }

  public unsubscribeToVehicleLocations(): Promise<void> {
    return unsubscribeToVehicleLocation();
  }

  private getRouteTraceImpl(): (rideId) => Observable<GetRouteTracesResponse> {
    return (rideId) => {
      return this.apiService.getRouteTraces(rideId).pipe(
        catchError((err) => {
          console.error(err);
          return of({
            traces: [],
            lastReadIndex: undefined,
          } as GetRouteTracesResponse);
        }),
      );
    };
  }

  private getLastRouteTraceImpl(rideId: string): () => Observable<number> {
    return () => this.apiService.getLastFetchedRouteTrace$(rideId).pipe(take(1));
  }
}
