import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SideBarTabId } from '../../types/entities';
import { filter } from 'rxjs/operators';

@Injectable()
export class SideBarService {
  public active$: Observable<string>;
  private activeSub$: BehaviorSubject<string>;
  constructor() {
    // Use 'empty' to fix "sidebar should not highlight Rides tab when no tab is selected" issue
    this.activeSub$ = new BehaviorSubject<SideBarTabId>('empty');
    this.active$ = this.activeSub$.pipe(filter((id) => !!id));
  }

  public set active(id: string) {
    this.activeSub$.next(id);
  }
}
