import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import * as fromApi from '../../api';

import { catchError, map } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { FirebaseService } from 'src/app/api/services/firebase/firebase.service';
import { SessionService } from 'src/app/api/services/api/session.service';
import { SetPasswordForTokenRequest, SetPasswordForTokenResponse } from 'src/app/api/endpoints/password-reset';
import { SessionUser } from '../store/types';
import {
  GetAuthenticationProviderRequest,
  GetAuthenticationProviderResponse,
} from '../../api/endpoints/get-authentication-provider';
import { User } from 'firebase/auth';
import { EmployeeImpersonationSessionDetails } from '@rootTypes/entities/auth';

@Injectable()
export class AuthApiService {
  constructor(
    private api: ApiService,
    private fb: FirebaseService,
    private session: SessionService,
  ) {}

  public signInWithEmailAndPassword(email: string, password: string): Observable<SessionUser> {
    return from(this.session.loginWithCredentials(email, password)).pipe(
      catchError((err) => {
        this.session.logout();
        throw err;
      }),
    );
  }

  public signInWithToken(token: string): Observable<SessionUser> {
    return from(this.session.loginWithToken(token)).pipe(
      catchError((err) => {
        this.session.logout();
        throw err;
      }),
    );
  }

  public signInWithGoogle(ssoUrl: string, relayState?: string): void {
    let url = ssoUrl;
    if (relayState) {
      const prefix = url.indexOf('?') >= 0 ? '&' : '?';
      url += `${prefix}RelayState=${encodeURIComponent(relayState)}`;
    }
    window.location.replace(url);
  }

  public checkAuth(): Observable<SessionUser | null> {
    return from(this.initialFbAuthResolver()).pipe(
      map((fbUser) => {
        if (fbUser) {
          return { userId: fbUser.uid };
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.log(err);
        return of(null);
      }),
    );
  }

  public signOut(): Observable<void> {
    return from(this.session.logout());
  }

  public signOutFromFirebase(): Observable<void> {
    return from(this.fb.signOut());
  }

  public getAccount(): Observable<fromApi.account.GetAccountResponse> {
    return this.api.getAccount();
  }

  public getAccounts(accountsPreset?: string): Observable<fromApi.account.GetAccountsResponse> {
    return this.api.getAccounts(accountsPreset);
  }

  public setAccount(request: fromApi.account.SetAccountRequest): Observable<fromApi.account.SetAccountResponse> {
    return this.api.setAccount(request);
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return from(this.fb.changePassword(oldPassword, newPassword));
  }

  public sendPasswordResetEmail(email: string): Observable<void> {
    return this.api.resetPasswordByEmail({ email });
  }

  public setPasswordForToken(request: SetPasswordForTokenRequest): Observable<SetPasswordForTokenResponse> {
    return this.api.setPasswordForToken(request);
  }

  public checkFirebaseAuth(): Observable<User | null> {
    return from(this.initialFbAuthResolver());
  }

  public getAuthenticationProvider(
    request: GetAuthenticationProviderRequest,
  ): Observable<GetAuthenticationProviderResponse> {
    return this.api.getAuthenticationProvider(request);
  }

  public getEmployeeImpersonationSessionDetails(): Observable<EmployeeImpersonationSessionDetails | null> {
    const getSessionDetails = async (): Promise<EmployeeImpersonationSessionDetails | null> => {
      if (wpEnvironment.userRole === 'admin') {
        return null;
      }
      const tokenResult = await this.fb.getIdTokenResult();
      if (tokenResult?.claims?.impersonatorUserId) {
        return {
          impersonatorUserId: tokenResult.claims.impersonatorUserId as string,
          authenticationExpirationTimestamp: tokenResult.claims.authenticationExpirationTimestamp as number,
        };
      }
      return null;
    };
    return from(getSessionDetails());
  }

  private initialFbAuthResolver(): Promise<User | null> {
    return new Promise<User | null>((resolve) => {
      let off: any;
      function callback(user: User | null): void {
        off();
        resolve(user);
      }
      // AngularFire approach was used in checking user authentication status.
      // We set an observer on firebase.Auth object
      // and remove it once firebase.Auth has been resolved
      off = this.fb.onAuthStateChanged(callback);
    });
  }
}
