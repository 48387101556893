import { Injectable } from '@angular/core';
import { RouterOutlets } from '@rootTypes/entities/router';
import { PortalRouterService } from '../../types/portal-router-service';
import { createPortalRoute } from '../../utils/create-portal-route';

export type SchoolEmployeeAccessManagerRouteData = {
  employeeId: string;
};

export const schoolEmployeeAccessManagerRoute = createPortalRoute<SchoolEmployeeAccessManagerRouteData>({
  path: ':employeeId/campus-access/manage',
  prefix: 'school-employees',

  outlet: RouterOutlets.DRAWER,
});

@Injectable({ providedIn: 'root' })
export class SchoolEmployeeAccessManagerRouterService extends PortalRouterService<SchoolEmployeeAccessManagerRouteData> {
  readonly route = schoolEmployeeAccessManagerRoute;
}
