import { Injectable } from '@angular/core';
import { RideLocation, RideStatus } from '@zum/flash-client';
import { Observable, of } from 'rxjs';
import { GetDriverBusReportResponse } from './endpoints/get-driver-bus-report';
import { YYYYMMDDString } from '@apiEntities';
import { VehicleLocation } from '@apiEntities/dashboard/vehicle-location';
import {
  ByRide,
  FlaggedCheckpointUpdateEvent,
  GetRouteTracesResponse,
  RideStreamItem,
  RouteIssueUpdateEvent,
} from './flash-api.service';

@Injectable()
export class FlashApiMockService {
  public connectFlashClient(): void {}

  public disconnectFlashClient(): void {}

  public subscribeToRidesPrimary(rideIds: string[]): Observable<ByRide<RideStreamItem>> {
    return of(undefined);
  }

  public subscribeToRidesSecondary(rideIds: string[]): Observable<ByRide<RideStreamItem>> {
    return of(undefined);
  }

  public subscribeToDBRList(
    reportIds: string[],
  ): Observable<{ driverBusReportId: string; result: GetDriverBusReportResponse }> {
    return of(undefined);
  }

  public subscribeToRideRouteTrace(rideId: string): Observable<GetRouteTracesResponse> {
    return of(undefined);
  }

  public subscribeToRideLocation(rideIds: string[]): Observable<RideLocation> {
    return of(undefined);
  }

  public unsubscribeToRideLocation(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public subscribeToRideStatus(): Observable<RideStatus> {
    return of(undefined);
  }

  public unsubscribeToRideStatus(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public subscribeToRouteIssues(
    districtProgramBatches: { districtProgramId: string; batchId: string }[],
  ): Observable<RouteIssueUpdateEvent> {
    return of(undefined);
  }

  public unsubscribeToRouteIssues(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public subscribeToFlaggedCheckpoints(
    yardIds: string[],
    date: YYYYMMDDString,
  ): Observable<FlaggedCheckpointUpdateEvent> {
    return of(undefined);
  }

  public unsubscribeToFlaggedCheckpoints(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public subscribeToVehicleLocations(): Observable<VehicleLocation> {
    return of(undefined);
  }

  public unsubscribeToVehicleLocations(): Promise<void> {
    return Promise.resolve(undefined);
  }
}
