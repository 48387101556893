import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CurrentUserProfile, GetProfileResponse } from '../../api/endpoints/get-profile';
import { UpdateProfileRequest, UpdateProfileResponse } from '../../api/endpoints/update-profile';

@Injectable()
export class UserProfileApiMockService {
  public getProfile(): Observable<GetProfileResponse> {
    const mockProfile: CurrentUserProfile = {
      name: {
        first: 'John',
        last: 'Smith',
      },
      email: 'john-smith-mock@ridezum.com',
      phoneNumber: '4245557799',
      avatarPath: '',
    };
    return of(mockProfile);
  }

  public updateProfile(request: UpdateProfileRequest): Observable<UpdateProfileResponse> {
    return of(undefined);
  }
}
