<mat-checkbox
  *ngIf="control"
  class="wp-checkbox"
  [ngClass]="{'large': isLarge, 'align-top': labelVerticalAlignment === 'top', 'error': isRequiredError}"
  [color]="'primary'"
  [disableRipple]="true"
  [indeterminate]="indeterminate"
  [@.disabled]="disableAnimation"
  [formControl]="control"
  (change)="onCheckboxValueChange($event)"
>
  <ng-content></ng-content>
</mat-checkbox>

<div class="error-message" *ngIf="isRequiredError">{{requiredErrorMessage}}</div>
