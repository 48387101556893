import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { SelectOption } from '../../form-controls';

@Component({
  selector: 'wp-inline-popup-option-selector',
  template: `
    <wp-custom-dropdown
      [disabled]="disabled"
      [xPosition]="'after'"
      [yPosition]="'center'"
      [applyBoxShadow]="false"
      (openChanged)="isOpen = $event"
    >
      <div class="trigger-wrap" [ngClass]="{ open: isOpen, disabled }" trigger>
        <div class="title">
          {{ title }}
        </div>
        <div class="selected-wrap">
          <div class="selected">
            @if (selectedTemplate) {
              <ng-container *ngTemplateOutlet="selectedTemplate"></ng-container>
            } @else {
              @if (selected?.length) {
                {{ defaultSelectedText }}
              } @else if (emptyText) {
                {{ emptyText }}
              } @else {
                <span>None selected</span>
              }
            }
          </div>
          <div class="edit-wrap">
            <wp-link-btn *ngIf="!disabled" [disabled]="disabled">Edit</wp-link-btn>
          </div>
        </div>
      </div>
      <ng-template #dropdownContent>
        <div class="popupable-container">
          <div class="arrow arrow-left"></div>
          <div [ngStyle]="{ width: popupWidthStr }" class="popup-wrap">
            <div class="close-wrap">
              <wp-icon-close [size]="'small'"></wp-icon-close>
            </div>
            <div class="popup-header">
              @if (popupHeaderTemplate) {
                <ng-container *ngTemplateOutlet="popupHeaderTemplate"></ng-container>
              } @else {
                <div class="popup-title">
                  {{ title }}
                </div>
                @if (popupNote?.length) {
                  <div class="popup-note">
                    {{ popupNote }}
                  </div>
                }
              }
            </div>
            <div
              *ngIf="options?.length"
              #group="wpCheckboxGroup"
              class="content checkboxes"
              wpCheckboxGroup
              [selected]="selected"
              (selectedChanged)="onCheckboxSelectedChanged($event)"
              (click)="$event.stopPropagation()"
            >
              <div class="clear-all-wrap">
                <wp-link-btn (clicked)="group.clearAll(); $event.stopPropagation()">Clear all</wp-link-btn>
              </div>
              <div class="checkbox-list customScroll">
                @for (option of options; track option) {
                  <div class="checkbox-wrap">
                    <wp-checkbox
                      wpCheckboxGroupItem
                      #item="wpCheckboxGroupItem"
                      [id]="option.value"
                      [selected]="item.checked$ | async"
                      (valueChanged)="item.toggle()"
                    >
                      <span style="display: inline-block; margin-left: 7px">
                        {{ option.displayLabel }}
                      </span>
                    </wp-checkbox>
                  </div>
                }
              </div>
            </div>
            <wp-divider-horizontal-line margin="0 0 20px"></wp-divider-horizontal-line>
            <div class="buttons">
              <div class="button">
                <wp-btn-secondary [width]="'120px'" [height]="'36px'" [fontSize]="'14px'"> Cancel</wp-btn-secondary>
              </div>
              <div class="button">
                <wp-btn-primary [width]="'120px'" [height]="'36px'" [fontSize]="'14px'" (clicked)="onApply()"
                  >Apply</wp-btn-primary
                >
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </wp-custom-dropdown>
  `,
  styleUrl: './inline-popup-option-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlinePopupOptionSelectorComponent implements OnChanges {
  @Input() public title: string;
  @Input() public selected: string[];
  @Input() public options: SelectOption[];
  @Input() public emptyText: string;
  @Input() public popupNote: string;
  @Input() public disabled: boolean;
  @Input() public popupWidthStr = '310px';

  // optional - provide custom templates for selected and popup header sections
  @Input() public selectedTemplate: TemplateRef<any>;
  @Input() public popupHeaderTemplate: TemplateRef<any>;

  @Output() public selectedChanged = new EventEmitter<string[]>();
  public isOpen: boolean;
  public defaultSelectedText: string;
  public currentSelected: string[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.currentSelected = this.selected;
    }
    if (changes.selected || changes.options) {
      this.defaultSelectedText = this.selected?.length
        ? this.selected
            .map((value) => {
              return (this.options || []).find((o) => o.value === value)?.displayLabel;
            })
            .join(', ')
        : null;
    }
  }

  onCheckboxSelectedChanged(event: string[]): void {
    this.currentSelected = event;
  }

  onApply(): void {
    this.selectedChanged.emit(this.currentSelected);
    this.currentSelected = null;
  }
}
