import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  getDefaultDistrictGradesFailed,
  getDefaultDistrictGradesRequested,
  getDefaultDistrictGradesSuccess,
} from '../actions/default-district-grades.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DefaultDistrictGradesApiService } from '../../../../api/services/default-district-grades-api.service';

@Injectable()
export class DefaultDistrictGradesEffects {
  constructor(
    private actions$: Actions,
    private api: DefaultDistrictGradesApiService,
  ) {}

  public getDefaultDistrictGradesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDefaultDistrictGradesRequested),
      switchMap(() => {
        return this.api.getDefaultDistrictGrades().pipe(
          map(({ standardGrades }) => getDefaultDistrictGradesSuccess({ standardGrades })),
          catchError((error) => of(getDefaultDistrictGradesFailed({ error }))),
        );
      }),
    ),
  );
}
