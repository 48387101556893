<wp-loaded-content
  [loading]="isLoading$ | async"
  [error]="loadingError$ | async"
  [contentTemplate]="contentTemplate"
  [loadingTemplate]="loadingTemplate"
  [errorTemplate]="errorTemplate"
></wp-loaded-content>

<ng-template #contentTemplate>
  <section class="page-container" *ngIf="contentMetadata$ | async; let metadata">
    <section class="page">
      <div class="info-card">
        <div class="info-card-title">
          <wp-portal-icon-fit
            class="info-card-title-icon"
            [path]="datasetIcon"
            [widthStr]="'36px'"
            [heightStr]="'36px'"
          ></wp-portal-icon-fit>
          <div [innerHTML]="metadata.action"></div>
        </div>

        <wp-divider-horizontal-line class="horizontal-line" [margin]="'16px 48px'"></wp-divider-horizontal-line>
        <div class="info-card-context" [innerHTML]="metadata.context"></div>

        @if (metadata.message) {
        <div class="info-card-message" [innerHTML]="metadata.message"></div>
        } @if(isDownload && metadata.actionButtonLabel) {
        <div class="info-card-download">
          <wp-link-download [href]="metadata.actionLink" [openInNewTab]="false"
            >{{metadata.actionButtonLabel}}
          </wp-link-download>
        </div>
        }
      </div>

      <div class="image-container" *ngIf="isImagePreview && metadata.actionLink">
        <img [src]="metadata.actionLink" width="100%" height="auto" alt="image-preview" />
      </div>
    </section>
  </section>
</ng-template>

<ng-template #loadingTemplate>
  <wp-section-spinner [sectionHeight]="'100%'"></wp-section-spinner>
</ng-template>

<ng-template #errorTemplate>
  <wp-section-error [sectionHeightStr]="'200px'" *ngIf="(loadingError$ | async); let error">
    <div class="loading-error">
      <div>{{error.text}}</div>
      <div>Context ID: {{error.originalError?.contextId}}</div>
    </div>
  </wp-section-error>
</ng-template>
