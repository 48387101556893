import { Directive, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CheckboxGroupDirective } from './checkbox-group.directive';
import { BehaviorSubject, Observable } from 'rxjs';
import { CheckboxItemInterface } from './types';

@Directive({
  selector: '[wpCheckboxGroupItem]',
  exportAs: 'wpCheckboxGroupItem',
})
export class CheckboxGroupItemDirective implements OnInit, OnChanges, OnDestroy, CheckboxItemInterface {
  @Input() public id: string;
  @Input() public disabled: boolean;

  disabled$: Observable<boolean>;
  checked$: Observable<boolean>;
  private disabled$$ = new BehaviorSubject<boolean>(false);
  private checked$$ = new BehaviorSubject<boolean>(false);
  private parent: CheckboxGroupDirective;

  constructor() {
    try {
      this.parent = inject(CheckboxGroupDirective);
    } catch (e) {
      console.log('Checkbox item should be used inside checkbox group directive', e);
    }
    this.disabled$ = this.disabled$$.asObservable();
    this.checked$ = this.checked$$.asObservable();
  }

  isDisabled(): boolean {
    return this.disabled$$.getValue();
  }

  isChecked(): boolean {
    return this.checked$$.getValue();
  }

  setChecked(value: boolean): void {
    this.checked$$.next(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.disabled$$.next(this.disabled || false);
    }
  }

  ngOnInit(): void {
    this.parent.addItem(this);
  }

  ngOnDestroy(): void {
    this.parent.removeItem(this.id);
  }

  public toggle(): void {
    if (!this.disabled) {
      this.checked$$.next(!this.checked$$.value);
      this.parent.emitChange();
    }
  }
}
