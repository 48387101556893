import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/campus-location-data.actions';

export interface CampusLocationsDataState {
  locations: {
    [locationId: string]: {
      isRemoving?: boolean;
      isRemoved?: boolean;
      removeError?: fromTypes.WpError;
      entity?: fromTypes.CampusLocation;
    };
  };
  // load locations by campuses
  locationsByCampuses: {
    [campusId: string]: fromTypes.EntityState<string[]>;
  };
  // set default location by campus
  defaultLocationsByCampuses: {
    [campusId: string]: {
      locationId: string;
      isLoading: boolean; // changing default
      error?: fromTypes.WpError | undefined;
    };
  };
}

export const initialLocationByCampusState: CampusLocationsDataState = {
  locations: {},
  locationsByCampuses: {},
  defaultLocationsByCampuses: {},
};

export const campusLocationDataReducer = createReducer<CampusLocationsDataState>(
  { ...initialLocationByCampusState },
  /**
   * Fetch locations
   */
  on(
    fromActions.getCampusLocationsRequested,
    fromActions.getCampusLocationsRequestedFromStudentDetails,
    (state, action) => {
      const { campusId } = action;
      const locationByCampusState = state.locationsByCampuses[campusId] || {
        isLoading: false,
        entity: undefined,
        error: undefined,
      };
      const res: CampusLocationsDataState = {
        ...state,
        locationsByCampuses: {
          ...state.locationsByCampuses,
          [campusId]: {
            ...locationByCampusState,
            isLoading: true,
          },
        },
      };
      return res;
    },
  ),
  on(fromActions.getCampusLocationsSuccess, (state, action) => {
    const { campusId, locations } = action;
    const locationIds = locations.map((l) => l.pickupDropoffLocationId);
    const primaryLocationId = locations.find((c) => c.isPrimary)?.pickupDropoffLocationId;
    const newLocations = locations.reduce((prev, curr) => {
      return { ...prev, [curr.pickupDropoffLocationId]: { entity: curr } };
    }, {});

    const res: CampusLocationsDataState = {
      ...state,
      defaultLocationsByCampuses: {
        ...state.defaultLocationsByCampuses,
        [campusId]: {
          locationId: primaryLocationId,
          isLoading: false,
        },
      },
      locations: {
        ...state.locations,
        ...newLocations,
      },
      locationsByCampuses: {
        ...state.locationsByCampuses,
        [campusId]: {
          isLoading: false,
          entity: locationIds,
          error: null,
        },
      },
    };
    return res;
  }),
  on(fromActions.getCampusLocationsFailed, (state, action) => {
    const { campusId, error } = action;
    const res: CampusLocationsDataState = {
      ...state,
      locationsByCampuses: {
        [campusId]: {
          isLoading: false,
          error,
        },
      },
    };
    return res;
  }),
  /**
   * Make location primary
   */
  on(fromActions.makeLocationPrimaryRequested, (state, action) => {
    const { campusId } = action;
    const primaryLocState = state.defaultLocationsByCampuses[campusId] || {
      locationId: undefined,
      error: undefined,
      isLoading: false,
    };
    const res: CampusLocationsDataState = {
      ...state,
      defaultLocationsByCampuses: {
        [campusId]: {
          ...primaryLocState,
          isLoading: true,
        },
      },
    };
    return res;
  }),
  on(fromActions.makeLocationPrimarySuccess, (state, action) => {
    const { campusId, locationId } = action;
    const primaryLocState = state.defaultLocationsByCampuses[campusId] || {
      locationId: undefined,
      error: undefined,
      isLoading: false,
    };
    const res: CampusLocationsDataState = {
      ...state,
      defaultLocationsByCampuses: {
        [campusId]: {
          ...primaryLocState,
          isLoading: false,
          locationId,
        },
      },
    };
    return res;
  }),
  on(fromActions.makeLocationPrimaryFailed, (state, action) => {
    const { campusId, locationId } = action;
    const primaryLocState = state.defaultLocationsByCampuses[campusId] || {
      locationId: undefined,
      error: undefined,
      isLoading: false,
    };
    const res: CampusLocationsDataState = {
      ...state,
      defaultLocationsByCampuses: {
        [campusId]: {
          ...primaryLocState,
          isLoading: false,
        },
      },
    };
    return res;
  }),

  /**
   * Delete (deactivate) location
   */
  on(fromActions.deleteLocationRequested, (state, action) => {
    const { locationId } = action;
    const locationState = state.locations[locationId] || {};
    const res: CampusLocationsDataState = {
      ...state,
      locations: {
        ...state.locations,
        [locationId]: {
          ...locationState,
          isRemoving: true,
          isRemoved: false,
          removeError: undefined,
        },
      },
    };
    return res;
  }),

  on(fromActions.deleteLocationSuccess, (state, action) => {
    const { locationId, campusId } = action;
    const locationState = state.locations[locationId] || {};
    const locationsByCampuses = state.locationsByCampuses[campusId]?.entity || [];
    const newLocationsByCampuses = locationsByCampuses.filter((id) => id !== locationId);
    const res: CampusLocationsDataState = {
      ...state,
      locationsByCampuses: {
        ...state.locationsByCampuses,
        [campusId]: {
          entity: newLocationsByCampuses,
          isLoading: false,
        },
      },
      locations: {
        ...state.locations,
        [locationId]: {
          ...locationState,
          isRemoving: false,
          isRemoved: true,
          removeError: undefined,
        },
      },
    };
    return res;
  }),

  on(fromActions.deleteLocationFailed, (state, action) => {
    const { locationId, error } = action;
    const locationState = state.locations[locationId] || {};
    const res: CampusLocationsDataState = {
      ...state,
      locations: {
        ...state.locations,
        [locationId]: {
          ...locationState,
          isRemoving: false,
          isRemoved: false,
          removeError: error,
        },
      },
    };
    return res;
  }),
);
