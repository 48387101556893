import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RideTraceDisplayMode } from '../types/ride-google-map';

@Component({
  selector: 'wp-annotated-trace-selector',
  template: `
    <div class="container">
      <button
        class="map-button"
        matTooltip="Ride trace"
        [matTooltipPosition]="'before'"
        [ngClass]="{ selected: selected === modes.TRACE }"
        (click)="selectedChanged.emit(modes.TRACE)"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.34783 6.21739C6.34783 6.21739 10.1159 10.2029 17.6522 18.1739M20 17C21.6569 17 23 18.3431 23 20C23 21.6569 21.6569 23 20 23C18.3431 23 17 21.6569 17 20C17 18.3431 18.3431 17 20 17ZM4 1C5.65685 1 7 2.34315 7 4C7 5.65685 5.65685 7 4 7C2.34315 7 1 5.65685 1 4C1 2.34315 2.34315 1 4 1Z"
            stroke="#959597"
            stroke-width="2"
            class="icon-path-stroke"
          />
        </svg>
      </button>
      <div class="separator"></div>
      <button
        class="map-button"
        matTooltip="Annotated trace"
        [matTooltipPosition]="'before'"
        [ngClass]="{ selected: selected === modes.ANNOTATED }"
        (click)="selectedChanged.emit(modes.ANNOTATED)"
      >
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1496_1287)">
            <path
              d="M26.4246 23.462V19.7162H23.8706V23.462H20.2952V25.6754H23.8706V29.4211H26.4246V25.6754H30V23.462H26.4246Z"
              fill="#959597"
              class="icon-path-fill"
            />
            <path
              d="M23.8365 6.38479V17.1964H26.3905V6.38479C26.3905 4.61407 25.7605 3.11578 24.5176 1.87287C23.2747 0.629966 21.7594 0 20.0057 0C18.252 0 16.7367 0.629966 15.4938 1.87287C14.2509 3.11578 13.6209 4.6311 13.6209 6.38479V23.4109C13.6209 24.4665 13.2463 25.3689 12.4972 26.118C11.748 26.8672 10.8456 27.2418 9.79001 27.2418C8.73439 27.2418 7.83201 26.8672 7.08286 26.118C6.33371 25.3689 5.95914 24.4665 5.95914 23.4109V9.1941C6.94665 8.81952 7.7639 8.24064 8.4109 7.42338C9.05789 6.60613 9.36436 5.70375 9.36436 4.68218C9.36436 3.3882 8.90465 2.2815 8.00227 1.36209C7.08286 0.442679 5.99319 0 4.68218 0C3.37117 0 2.2815 0.459705 1.36209 1.36209C0.442679 2.26447 0 3.3882 0 4.68218C0 5.70375 0.323496 6.60613 0.953462 7.42338C1.58343 8.24064 2.40068 8.81952 3.40522 9.1941V23.4109C3.40522 25.1816 4.03519 26.6799 5.27809 27.9228C6.521 29.1657 8.03632 29.7957 9.79001 29.7957C11.5437 29.7957 13.059 29.1657 14.3019 27.9228C15.5448 26.6799 16.1748 25.1646 16.1748 23.4109V6.38479C16.1748 5.32917 16.5494 4.42679 17.2985 3.67764C18.0477 2.92849 18.9501 2.55392 20.0057 2.55392C21.0613 2.55392 21.9637 2.92849 22.7128 3.67764C23.462 4.42679 23.8365 5.32917 23.8365 6.38479ZM4.68218 6.81044C4.08627 6.81044 3.59251 6.60613 3.16686 6.1975C2.75823 5.78888 2.55392 5.27809 2.55392 4.68218C2.55392 4.08627 2.75823 3.59251 3.16686 3.16686C3.57548 2.75823 4.08627 2.55392 4.68218 2.55392C5.27809 2.55392 5.77185 2.75823 6.1975 3.16686C6.60613 3.57548 6.81044 4.08627 6.81044 4.68218C6.81044 5.27809 6.60613 5.77185 6.1975 6.1975C5.78888 6.60613 5.27809 6.81044 4.68218 6.81044Z"
              fill="#959597"
              class="icon-path-fill"
            />
          </g>
          <defs>
            <clipPath id="clip0_1496_1287">
              <rect width="30" height="29.7957" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  `,
  styleUrl: './annotated-trace-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotatedTraceSelectorComponent {
  @Input() public selected: RideTraceDisplayMode;
  @Output() public selectedChanged = new EventEmitter<RideTraceDisplayMode>();

  public modes = RideTraceDisplayMode;
}
