import { booleanAttribute, Directive, ElementRef, inject, input } from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { filter, fromEvent } from 'rxjs';

@Directive({
  selector: '[wpClickOutside]',
})
export class ClickOutsideDirective {
  enabled = input<boolean, unknown>(true, {
    alias: 'wpClickOutside',
    transform: booleanAttribute,
  });

  clickedOutside = outputFromObservable(
    fromEvent(document, 'click').pipe(
      filter((event) => this.enabled() && !this.elementRef.nativeElement.contains(event.target as Node)),
    ),
  );

  private readonly elementRef = inject<ElementRef<Node>>(ElementRef);
}
