import { GetAnnotatedTracesForRideResponse } from '../../../api/endpoints/get-annotated-traces-for-ride';
import { BoundPolylineTooltip, Polyline, PolylineDrawer } from './polyline-drawer';
import { MapLocation } from '../interfaces';
import { AnnotatedTrace } from '../../../api/endpoints/get-annotated-traces-for-driver';
import { UTCTime } from '@rootTypes/utils/common/date';
import { drawArrowIcon } from './car-mover/utils';
import { Segment } from '@apiEntities/rides/annotated-ride-traces';

const htmlGetter = (annotation: Segment, trace: AnnotatedTrace, timezone: string): string => {
  const records: { label: string; value: string; sub?: string }[] = [];
  records.push({ label: 'Type', value: annotation.type.split('_').join(' ') });
  records.push({ label: 'Speed', value: '' + trace.speedMph + ' mph' });
  const formatter = new UTCTime(trace.timestamp, timezone);
  const valueTZ = formatter.getDisplayTimeStr();
  const userTZFormatter = new UTCTime(trace.timestamp);
  const valueUserTZ = userTZFormatter.getDisplayTimeStr();
  records.push({
    label: 'Time',
    value: valueTZ,
    sub: valueUserTZ,
  });
  const recordsHTMLs = records.map((r) => {
    const subHTML = r.sub ? `<div style="color: #636365">${r.sub}</div>` : '';
    return `<div style="display: flex; align-items: flex-start; margin-bottom: 3px">
            <div style="font-weight: 500; color: #4a4a4a; margin-right: 5px;">${r.label}:</div><div>${r.value}${subHTML}</div>
      </div>`;
  });
  return `
  <div style="position: relative; height: 0px;">
    <div style="min-height: 55px; min-width: 170px; position: absolute; bottom: 15px; background-color: white; padding: 8px; border-radius: 5px; font-size: 12px; -webkit-box-shadow: 0px 3px 12px -7px rgba(0,0,0,0.75); -moz-box-shadow: 0px 3px 12px -7px rgba(0,0,0,0.75); box-shadow: 0px 3px 12px -7px rgba(0,0,0,0.75);">
      ${recordsHTMLs.join('')}
    </div>
   </div>
  `;
};

export class AnnotatedRideTraceRenderer {
  private polylineDrawer: PolylineDrawer;

  constructor(map: google.maps.Map) {
    this.polylineDrawer = new PolylineDrawer(map);
  }

  public setAnnotatedTraces(traces: GetAnnotatedTracesForRideResponse): void {
    this.polylineDrawer.setPolylines(this.getPolylinesForAnnotatedTraces(traces));
  }

  public hide(): void {
    this.polylineDrawer.setPolylines([]);
  }

  private getPolylinesForAnnotatedTraces(annotatedTraces: GetAnnotatedTracesForRideResponse): Polyline[] {
    if (!annotatedTraces) {
      return [];
    }
    const { detailedAnnotations, traces } = annotatedTraces;
    if (!detailedAnnotations?.length) {
      return [];
    }
    if (!traces?.length) {
      return [];
    }
    const result: Polyline[] = [];
    detailedAnnotations.forEach((detailedAnnotation, index) => {
      detailedAnnotation.segments.forEach((detailedAnnotationSegment: Segment) => {
        const { timezone, segmentTimeInterval, polylineOptions } = detailedAnnotationSegment;
        const { startTimestamp, endTimestamp } = segmentTimeInterval;
        let startIndex = traces.findIndex((trace) => trace.timestamp >= startTimestamp);
        if (startIndex === -1) {
          startIndex = annotatedTraces.traces.length;
        }
        let endIndex = traces.findIndex((trace) => trace.timestamp > endTimestamp);
        if (endIndex === -1) {
          endIndex = annotatedTraces.traces.length;
        }
        const tooltips: BoundPolylineTooltip[] = [];
        const path: MapLocation[] = [];
        for (let i = startIndex; i < endIndex; i++) {
          const curr = traces[i];
          const tooltip: BoundPolylineTooltip = {
            htmlGetter: () => htmlGetter(detailedAnnotationSegment, curr, timezone),
            width: '170px',
            height: '0', // height is given by the htmlGetter
            zIndex: index + 1,
            location: curr,
          };
          tooltips.push(tooltip);
          path.push({ lat: curr.lat, lng: curr.lng });
        }
        if (path?.length) {
          result.push({
            id: detailedAnnotationSegment.type + index,
            zIndex: index,
            path,
            tooltips,
            icons: [...drawArrowIcon(true)],
            ...polylineOptions,
          });
        }
      });
    });
    return result;
  }
}
