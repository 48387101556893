import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ContentChild,
  ChangeDetectorRef,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { getPositionsFromInputParameters } from '../get-positions-from-input-parameters';
import { InputXPosition, InputYPosition } from '../custom-overlay';

@Component({
  selector: 'wp-custom-dropdown',
  template: `
    <div
      [ngStyle]="divStyles"
      class="wp-custom-dropdown"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      #triggerEl
      (click)="toggleOpen()"
    >
      <ng-content select="[trigger]"></ng-content>
    </div>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayPositions]="positions"
      (overlayOutsideClick)="close()"
    >
      <div *ngIf="isOpen" (click)="close()">
        <div [ngClass]="{ elevated: applyBoxShadow }">
          <ng-template [ngTemplateOutlet]="dropdownContent"></ng-template>
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .elevated {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDropdownComponent implements OnInit {
  @Input() public yPosition: InputYPosition = 'below';
  @Input() public xPosition: InputXPosition = 'center';
  @Input() public disabled: boolean;
  @Input() public divStyles: { [key: string]: string } = {};
  @Input() public applyBoxShadow = true;
  @Output() public openChanged = new EventEmitter<boolean>();

  @ContentChild('dropdownContent', { read: TemplateRef }) public dropdownContent: TemplateRef<any>;

  public isOpen = false;
  public positions: ConnectedPosition[];
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.positions = getPositionsFromInputParameters(this.xPosition, this.yPosition);
  }

  public toggleOpen(): void {
    if (this.disabled) {
      return;
    }
    this.isOpen = !this.isOpen;
    this.openChanged.emit(this.isOpen);
  }

  public open(): void {
    if (this.disabled) {
      return;
    }
    this.isOpen = true;
    this.openChanged.emit(this.isOpen);
    this.cd.detectChanges();
  }

  public close(): void {
    if (this.disabled) {
      return;
    }
    this.isOpen = false;
    this.openChanged.emit(this.isOpen);
    this.cd.detectChanges();
  }
}
