import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RideFilterRequest, RideFilterResponse } from '../endpoints/rides';
import { GetRideRequest, GetRideResponse } from '../endpoints/get-ride';
import { GetRidePolylineRequest, GetRidePolylineResponse } from '../endpoints/get-ride-polyline';
import { GetRideDriverFeeRequest, GetRideDriverFeeResponse } from '../endpoints/get-ride-driver-fee';
import {
  GetEndOfRideInspectionAbstractByRideRequest,
  GetEndOfRideInspectionAbstractByRideResponse,
} from '../endpoints/get-end-of-ride-inspection-abstract-by-ride';
import {
  GetRouteTracesForRideIdRequest,
  GetRouteTracesForRideIdResponse,
} from '../endpoints/get-route-traces-for-ride-id';
import { GetRideHistoryRequest, GetRideHistoryResponse } from '../endpoints/get-ride-history';
import {
  GetRideVendorAssignmentHistoryRequest,
  GetRideVendorAssignmentHistoryResponse,
} from '../endpoints/get-ride-vendor-assignment-history';

@Injectable()
export class RideApiMockService {
  public rides(request: RideFilterRequest): Observable<RideFilterResponse> {
    return of({
      rideIds: [],
      totalRides: 0,
    });
  }

  public getRide(request: GetRideRequest): Observable<GetRideResponse> {
    return of(undefined);
  }

  public getRidePolyline(request: GetRidePolylineRequest): Observable<GetRidePolylineResponse> {
    return of(undefined);
  }

  public getRideDriverFee(request: GetRideDriverFeeRequest): Observable<GetRideDriverFeeResponse> {
    return of(undefined);
  }

  public getEndOfRideInspectionAbstractByRide(
    request: GetEndOfRideInspectionAbstractByRideRequest,
  ): Observable<GetEndOfRideInspectionAbstractByRideResponse> {
    return of(undefined);
  }

  public getRouteTracesForRideId(request: GetRouteTracesForRideIdRequest): Observable<GetRouteTracesForRideIdResponse> {
    return of(undefined);
  }

  public getRideHistory(request: GetRideHistoryRequest): Observable<GetRideHistoryResponse> {
    return of(undefined);
  }

  public getRideVendorAssignmentHistory(
    request: GetRideVendorAssignmentHistoryRequest,
  ): Observable<GetRideVendorAssignmentHistoryResponse> {
    return of(undefined);
  }
}
