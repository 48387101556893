import { BehaviorSubject, Observable } from 'rxjs';
import { CheckboxItemInterface } from './types';

export interface CheckboxItemData {
  id: string;
  disabled?: boolean;
}

export class CheckboxItem implements CheckboxItemInterface {
  id: string;
  disabled$: Observable<boolean>;
  checked$: Observable<boolean>;

  private disabled$$ = new BehaviorSubject<boolean>(false);
  private checked$$ = new BehaviorSubject<boolean>(false);
  constructor(data: CheckboxItemData) {
    this.id = data.id;
    this.disabled$$.next(data.disabled || false);
    this.checked$ = this.checked$$.asObservable();
    this.disabled$ = this.disabled$$.asObservable();
  }
  isChecked(): boolean {
    return this.checked$$.getValue();
  }
  isDisabled(): boolean {
    return this.disabled$$.getValue();
  }
  setChecked(value: boolean): void {
    this.checked$$.next(value);
  }
  toggle(): void {
    this.checked$$.next(!this.checked$$.getValue());
  }
}
