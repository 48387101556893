import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { environment } from '../../environments/environment';
import { ImageApiService } from './services/image-api.service';
import { ImageApiMockService } from './services/image-api-mock.service';
import { FlashApiService } from './flash-api.service';
import { FlashApiMockService } from './flash-api-mock.service';
import { RideApiService } from './services/ride-api.service';
import { RideApiMockService } from './services/ride-api-mock.service';
import { DefaultDistrictGradesApiService } from './services/default-district-grades-api.service';
import { DefaultDistrictGradesApiMockService } from './services/default-district-grades-api-mock.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: ImageApiService,
      useClass: environment.isMockAPI ? ImageApiMockService : ImageApiService,
    },
    {
      provide: FlashApiService,
      useClass: environment.isMockAPI ? FlashApiMockService : FlashApiService,
    },
    {
      provide: RideApiService,
      useClass: environment.isMockAPI ? RideApiMockService : RideApiService,
    },
    {
      provide: DefaultDistrictGradesApiService,
      useClass: environment.isMockAPI ? DefaultDistrictGradesApiMockService : DefaultDistrictGradesApiService,
    },
  ],
})
export class ApiModule {}
