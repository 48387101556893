import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DownloadImageSizePx } from '../../types/utils';

@Injectable()
export class ImageApiMockService {
  public getImageBase64(imagePath: string, size?: DownloadImageSizePx): Promise<string> {
    return Promise.resolve('');
  }

  public downloadRawImage(storagePath: string, feature: string): Promise<string> {
    return Promise.resolve('');
  }

  public saveRawImageToFile(storagePath: string, feature: string, filename: string): Promise<any> {
    return Promise.resolve(undefined);
  }

  public uploadImage(imageBase64: string): Observable<string> {
    return of('');
  }

  public cleanCache(): void {}
}
