import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { openErrorPopup, State } from '@rootStore';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs';
import { SnackbarService } from '../../../../../shared/snackbar/snackbar.service';
import * as fromTypes from '../../../types';
import * as fromActions from '../../actions/campus-details/locations-tab.actions';
import * as fromDataActions from '../../actions/campus-location-data.actions';
import * as fromDetailsSelectors from '../../selectors/campus-details/campus-details.selectors';
import * as fromSelectors from '../../selectors/campus-details/locations-tab.selectors';

@Injectable()
export class LocationsTabEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private toastService: SnackbarService,
  ) {}

  public onShowPhotosClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.showPhotosForLocationClicked),
      map((action) => {
        const { locationId } = action;
        this.store.dispatch(fromActions.selectLocation({ locationId }));
        return fromActions.setViewMode({ viewMode: fromTypes.CampusLocationViewMode.PHOTOS });
      }),
    ),
  );

  public onLocationItemClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.locationItemClicked),
      withLatestFrom(this.store.select(fromSelectors.getSelectedLocationId)),
      map(([action, currentId]) => {
        const { locationId } = action;
        const newSelectedId = locationId === currentId ? undefined : locationId;
        return fromActions.selectLocation({ locationId: newSelectedId });
      }),
    ),
  );

  // change view mode to map, if the selected location has no photos
  public onSelectedLocationChanged = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectLocation),
      switchMap((action) => {
        const { locationId } = action;
        return this.store.select(fromSelectors.getSelectedLocation).pipe(
          filter((location) => !!location),
          take(1),
          filter((location) => !(location.photos && location.photos.length)),
          map(() => {
            return fromActions.setViewMode({ viewMode: fromTypes.CampusLocationViewMode.MAP });
          }),
        );
      }),
    ),
  );

  /**
   * Make location primary
   */
  public makeLocationPrimaryClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.makeLocationPrimaryClicked),
      withLatestFrom(this.store.select(fromDetailsSelectors.getCampus)),
      map(([action, campus]) => {
        const { locationId } = action;
        const { id: campusId, districtId } = campus;
        return fromDataActions.makeLocationPrimaryRequested({ locationId, campusId, districtId });
      }),
    ),
  );

  /**
   * Open toast on make location primary success
   */
  public makeLocationPrimarySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDataActions.makeLocationPrimarySuccess),
        map((action) => this.toastService.success('Successfully updated primary location')),
      ),
    {
      dispatch: false,
    },
  );

  /**
   * Open popup on make primary location failed
   */
  public makeLocationPrimaryFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDataActions.makeLocationPrimaryFailed),
      map((action) => openErrorPopup({ error: action.error })),
    ),
  );

  /**
   * Delete (deactivate) location
   */
  public deleteLocationClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteLocationClicked),
      withLatestFrom(this.store.select(fromDetailsSelectors.getCampus)),
      map(([action, campus]) => {
        const { locationId } = action;
        const { id: campusId, districtId } = campus;
        return fromDataActions.deleteLocationRequested({ locationId, campusId, districtId });
      }),
    ),
  );

  /**
   * Open toast on delete location success
   */
  public deleteLocationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDataActions.deleteLocationSuccess),
        map((action) => this.toastService.success('Successfully removed location')),
      ),
    {
      dispatch: false,
    },
  );

  /**
   * Open popup on delete location failed
   */
  public deleteLocationFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDataActions.deleteLocationFailed),
      map((action) => openErrorPopup({ error: action.error })),
    ),
  );
}
