import * as convertors from './convertors';

export { convertors };
export * from './response';

export type GetVendorEmployeeRequest = {
  vendorEmployeeId: string;
  _projections?: VendorEmployeeProjection[];
};

export enum VendorEmployeeProjection {
  permission = 'permission',
  vendor = 'vendor',
  yards = 'yards',
  roles = 'roles',
}
