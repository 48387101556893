import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationControlsComponent } from './pagination-controls/pagination-controls.component';
import { SquareBorderComponent } from './square-border/square-border.component';
import { SectionSpinnerComponent } from './section-spinner/section-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EllipsisComponent } from './ellipsis/ellipsis.component';
import { PillComponent } from './pill/pill.component';
import { MultiClampComponent } from './multi-clamp/multi-clamp.component';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';
import { PageModule } from '../page/page.module';
import { MatMenuModule } from '@angular/material/menu';
import { DividerComponent } from './divider/divider.component';
import { RoundNumberIndicatorComponent } from './round-number-indicator/round-number-indicator.component';
import { NoItemsResultComponent } from './no-items-result/no-items-result.component';
import { InfoWindowComponent } from './info-window/info-window.component';
import { GreyClosableBlockComponent } from './grey-closable-block/grey-closable-block.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ListResultBarComponent } from './list-result-bar/list-result-bar.component';
import { PipesModule } from '../pipes/pipes.module';
import { SquarePillComponent } from './square-pill/square-pill.component';
import { NoItemsResultsSubsectionComponent } from './no-items-results-subsection/no-items-results-subsection.component';
import { SectionErrorComponent } from './section-error/section-error.component';
import { PlaceholderBoxComponent } from './placeholder-box/placeholder-box.component';
import { ChecklistItemComponent } from './checklist-item/checklist-item.component';
import { ListResultBarFullComponent } from './list-result-bar-full/list-result-bar-full.component';
import { TypographyModule } from '../typography/typography.module';
import { EmptyFolderImageComponent } from './empty-folder-image/empty-folder-image.component';
import { RoundRatingBadgeComponent } from './round-rating-badge/round-rating-badge.component';
import { DefaultToggleComponent } from './default-toggle/default-toggle.component';
import { PlaceMiddleSectionComponent } from './place-middle-section/place-middle-section.component';
import { AnimatedWidthChangeComponent } from './animated-width-change/animated-width-change.component';
import { DirectivesModule } from '../directives/directives.module';
import { AddEditActionButtonsComponent } from './add-edit-action-buttons/add-edit-action-buttons.component';
import { ButtonsModule } from '../buttons/buttons.module';
import { DisabledMaskComponent } from './disabled-mask/disabled-mask.component';
import { AddEditActionButtonsMiniComponent } from './add-edit-action-buttons-mini/add-edit-action-buttons-mini.component';
import { PortalIconComponent } from './portal-icon/portal-icon.component';
import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { ArrowIconsModule } from '../icons/arrow-icons/arrow-icons.module';
import { TextDividerDotComponent } from './text-divider-dot/text-divider-dot.component';
import { TextDividerVerticalLineComponent } from './text-divider-vertical-line/text-divider-vertical-line.component';
import { ThreeDotsComponent } from './three-dots/three-dots.component';
import { RichOptionCardComponent } from './rich-option-card/rich-option-card.component';
import { FieldErrorComponent } from './field-error/field-error.component';
import { AnimationsModule } from '../animations/animations.module';
import { AlertWithIconWarningComponent } from './alert-with-icon-warning/alert-with-icon-warning.component';
import { AlertWithIconInfoComponent } from './alert-with-icon-info/alert-with-icon-info.component';
import { PortalDrawerEditorPageComponent } from './portal-drawer-editor-page/portal-drawer-editor-page.component';
import { FollowupActionsPopupComponent } from './followup-actions-popup/followup-actions-popup.component';
import { StatusWithLabelComponent } from './status-with-label/status-with-label.component';
import { ExpandContainerComponent } from './expand-container/expand-container.component';
import { RoundMarkerComponent } from './round-marker/round-marker.component';
import { InlineServerErrorComponent } from './inline-error/inline-server-error.component';
import { AlertsModule } from '../alerts/alerts.module';
import { RemovedBadgeComponent } from './removed-badge/removed-badge.component';
import { PortalBasicEditorPopupComponent } from './portal-basic-editor-popup/portal-basic-editor-popup.component';
import { StackLayoutModule } from '../layouts/stack-layout/stack-layout.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { CustomDropdownComponent } from './custom-overlay/custom-dropdown/custom-dropdown.component';
import { CustomHtmlTooltipComponent } from './custom-overlay/custom-html-tooltip/custom-html-tooltip.component';
import { RangeSelectComponent } from './range-select/range-select.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { DoubleRangeSelectColorDirective } from './range-select/double-range-select-color.directive';
import { DoubleRangeSelectColorTickDirective } from './range-select/double-range-select-color-tick.directive';
import { DisplayExactLocationComponent } from './display-exact-location/display-exact-location.component';
import { DisplayAddressWithExactLocationComponent } from './display-address-with-exact-location/display-address-with-exact-location.component';
import { SimplePaginationControlComponent } from './simple-pagination-control/simple-pagination-control.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { PlusMinusQuantitySelectorComponent } from './plus-minus-quantity-selector/plus-minus-quantity-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DividerHorizontalLineComponent } from './divider-horizontal-line/divider-horizontal-line.component';
import { BackLinkWithArrowComponent } from './back-link-with-arrow/back-link-with-arrow.component';
import { DropdownPaneWithButtonsComponent } from './dropdown-pane-with-buttons/dropdown-pane-with-buttons.component';
import { DividerVerticalLineComponent } from './divider-vertical-line/divider-vertical-line.component';
import { ManageEntityButtonWithDropdownComponent } from './manage-entity-button-with-dropdown/manage-entity-button-with-dropdown.component';
import { DropdownModule } from '../form-controls/dropdown/dropdown.module';
import { MenuInlineComponent } from './menu-inline/menu-inline.component';
import { BadgeComponent } from './badge/badge.component';
import { AlertWithIconCheckOkComponent } from './alert-with-icon-check-ok/alert-with-icon-check-ok.component';
import { BlobImgComponent } from './blob-img/blob-img.component';
import { PortalPdfViewerComponent } from './portal-pdf-viewer/portal-pdf-viewer.component';
import { VerticalCollapseToggleButtonComponent } from './vertical-collapse-toggle-button/vertical-collapse-toggle-button.component';
import { LoadedLabelComponent } from './loaded-label/loaded-label.component';
import { FileInputButtonComponent } from './file-input-button/file-input-button.component';
import { PdfUtilsModule } from '../pdf-utils/pdf-utils.module';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { PortalRouterLinkComponent } from './portal-router-link/portal-router-link.component';
import { RouterLink } from '@angular/router';
import { PortalIconFitComponent } from './portal-icon-fit/portal-icon-fit.component';
import { SectionTitleGrayFullWidthComponent } from './section-title-gray-full-width/section-title-gray-full-width.component';
import { SeparatorHorizontalWithMidTextComponent } from './separator-horizontal-with-mid-text/separator-horizontal-with-mid-text.component';
import { CustomTooltipExpandingDropdownComponent } from './custom-overlay/custom-tooltip-expanding-dropdown/custom-tooltip-expanding-dropdown.component';
import { DurationInputComponent } from './duration-input/duration-input.component';
import { InlinePopupOptionSelectorComponent } from './inline-popup-option-selector/inline-popup-option-selector.component';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { CheckboxGroupModule } from '../checkbox-group/checkbox-group.module';

const components = [
  AddEditActionButtonsComponent,
  AnimatedWidthChangeComponent,
  ChecklistItemComponent,
  PaginationControlsComponent,
  SquareBorderComponent,
  SectionSpinnerComponent,
  EllipsisComponent,
  InfoWindowComponent,
  PillComponent,
  MultiClampComponent,
  WorkInProgressComponent,
  DividerComponent,
  RoundNumberIndicatorComponent,
  NoItemsResultComponent,
  GreyClosableBlockComponent,
  SquarePillComponent,
  ProgressBarComponent,
  PlaceholderBoxComponent,
  ListResultBarComponent,
  NoItemsResultsSubsectionComponent,
  SectionErrorComponent,
  EmptyFolderImageComponent,
  RoundRatingBadgeComponent,
  PlaceMiddleSectionComponent,
  DisabledMaskComponent,
  AddEditActionButtonsMiniComponent,
  PortalIconComponent,
  TextDividerDotComponent,
  TextDividerVerticalLineComponent,
  ThreeDotsComponent,
  RichOptionCardComponent,
  FieldErrorComponent,
  AlertWithIconWarningComponent,
  AlertWithIconInfoComponent,
  PortalDrawerEditorPageComponent,
  FollowupActionsPopupComponent,
  StatusWithLabelComponent,
  ExpandContainerComponent,
  RoundMarkerComponent,
  InlineServerErrorComponent,
  RemovedBadgeComponent,
  PortalBasicEditorPopupComponent,
  RangeSelectComponent,
  CustomHtmlTooltipComponent,
  CustomDropdownComponent,
  DisplayExactLocationComponent,
  DisplayAddressWithExactLocationComponent,
  PlusMinusQuantitySelectorComponent,
  SimplePaginationControlComponent,
  ImageEditorComponent,
  DividerHorizontalLineComponent,
  BackLinkWithArrowComponent,
  DropdownPaneWithButtonsComponent,
  DividerVerticalLineComponent,
  ListResultBarFullComponent,
  DefaultToggleComponent,
  RemovedBadgeComponent,
  DoubleRangeSelectColorDirective,
  DoubleRangeSelectColorTickDirective,
  ManageEntityButtonWithDropdownComponent,
  MenuInlineComponent,
  BadgeComponent,
  BlobImgComponent,
  AlertWithIconCheckOkComponent,
  VerticalCollapseToggleButtonComponent,
  LoadedLabelComponent,
  FileInputButtonComponent,
  PortalRouterLinkComponent,
  PortalIconFitComponent,
  SectionTitleGrayFullWidthComponent,
  CustomTooltipExpandingDropdownComponent,
  PortalPdfViewerComponent,
  SeparatorHorizontalWithMidTextComponent,
  DurationInputComponent,
  InlinePopupOptionSelectorComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatMenuModule,
    PageModule,
    PipesModule,
    TypographyModule,
    DirectivesModule,
    ButtonsModule,
    IconCloseModule,
    ArrowIconsModule,
    AnimationsModule,
    AlertsModule,
    StackLayoutModule,
    OverlayModule,
    NgxSliderModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    DropdownModule,
    PdfUtilsModule,
    NgxMaskDirective,
    RouterLink,
    CheckboxModule,
    CheckboxGroupModule,
  ],
  providers: [provideNgxMask()],
  exports: [...components],
})
export class ComponentsModule {}
