import { PortalPolygonOptions } from '../types/portal-polygon';
import LatLngLiteral = google.maps.LatLngLiteral;
import { getPolygonCenter } from '../map-utils/get-polygon-center';
import { Observable, Subject, Subscription } from 'rxjs';

export class PolygonRenderer {
  private gPolygons: RenderedPolygon[] = [];

  private subs: Subscription;

  constructor(private map: google.maps.Map) {}

  public async setPolygons(polygons: PortalPolygonOptions[]): Promise<void> {
    if (!polygons) {
      return;
    }
    const removedPolygons = this.getRemovedPolygons(this.gPolygons, polygons);
    const addedPolygons = this.getAddedPolygons(this.gPolygons, polygons);
    removedPolygons.forEach((removed) => {
      removed.remove();
      const removedIndex = this.gPolygons.findIndex((gp) => gp === removed);
      this.gPolygons.splice(removedIndex, 1);
    });
    this.gPolygons.push(...addedPolygons);
    const promises = addedPolygons.map((p) => p.draw());
    await Promise.all(promises);
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.subs = new Subscription();
    this.gPolygons.forEach((p) => {
      if (p.options.tooltip?.closeOtherTooltipsOnOpen) {
        const s = p.openTooltipEvent$.subscribe(() => {
          this.gPolygons.forEach((other) => {
            if (other !== p) {
              other.closeTooltip();
            }
          });
        });
        this.subs.add(s);
      }
    });
  }

  public destroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  private getAddedPolygons(previous: RenderedPolygon[], newPolygons: PortalPolygonOptions[]): RenderedPolygon[] {
    const result: RenderedPolygon[] = [];
    newPolygons.forEach((newPolygon) => {
      if (!newPolygon?.shape?.compareId) {
        result.push(new RenderedPolygon(newPolygon, this.map));
      } else {
        const wasAdded = previous.some((prevPolygon) => prevPolygon.compareId === newPolygon.shape.compareId);
        if (!wasAdded) {
          result.push(new RenderedPolygon(newPolygon, this.map));
        }
      }
    });
    return result;
  }

  private getRemovedPolygons(previous: RenderedPolygon[], newPolygons: PortalPolygonOptions[]): RenderedPolygon[] {
    const result = [];
    previous.forEach((prevPolygon) => {
      const prevCompareId = prevPolygon.compareId;
      if (!prevCompareId) {
        result.push(prevPolygon);
      } else {
        const isPresentInTheNewBatch = newPolygons.some((newPolygon) => newPolygon.shape.compareId === prevCompareId);
        if (!isPresentInTheNewBatch) {
          result.push(prevPolygon);
        }
      }
    });
    return result;
  }
}

export class RenderedPolygon {
  public readonly compareId: string;
  public openTooltipEvent$: Observable<void>;
  private gPolygon: google.maps.Polygon;
  private polygonMarker: google.maps.Marker;
  private tooltip: any;
  private borderPolyline: google.maps.Polyline;
  private mapClickListener: google.maps.MapsEventListener;
  private pointsChangedListenerSetAt: google.maps.MapsEventListener;
  private pointsChangedListenerInsertAt: google.maps.MapsEventListener;
  private openTooltipEvent$$ = new Subject<void>();
  private closeTooltipSub: Subscription;
  constructor(
    public options: PortalPolygonOptions,
    private map: google.maps.Map,
  ) {
    this.compareId = options.shape.compareId;
    this.openTooltipEvent$ = this.openTooltipEvent$$.asObservable();
  }

  public async draw(): Promise<void> {
    this.gPolygon = new google.maps.Polygon({
      paths: this.options.shape.paths,
      strokeColor: this.options.shape.strokeColor,
      strokeOpacity: this.options.shape.strokeOpacity,
      strokeWeight: this.options.shape.strokeWeight,
      fillColor: this.options.shape.fillColor,
      fillOpacity: this.options.shape.fillOpacity,
      editable: this.options.shape.editable,
      draggable: this.options.shape.draggable,
      zIndex: this.options.shape.zIndex,
    });

    if (this.options.marker) {
      this.polygonMarker = new google.maps.Marker({
        ...this.options.marker,
        position: this.options.marker.position
          ? ({ lat: this.options.marker.position.lat, lng: this.options.marker.position.lng } as LatLngLiteral)
          : this.getPolygonCenter(),
      });
      this.polygonMarker.setMap(this.map);
    }

    if (this.options.tooltip) {
      this.gPolygon.addListener('click', async (event) => {
        if (!this.tooltip || this.options.tooltip.openAtClickPosition) {
          if (this.tooltip) {
            if (this.closeTooltipSub) {
              this.closeTooltipSub.unsubscribe();
            }
            this.tooltip.remove();
          }
          const cm = await import('./custom-html-marker');
          this.tooltip = new cm.CustomHtmlMarker(
            this.options.tooltip?.openAtClickPosition && event.latLng ? event.latLng : this.getPolygonCenter(),
            this.map,
            '',
            '0px',
            '0px',
            (this.options.shape.zIndex || 0) + 1,
            {
              tooltip: {
                html: this.options.tooltip.html,
                fix: true,
              },
              htmlListeners: this.options.tooltip.htmlListeners ?? [],
            },
          );
          if (this.options.shape.highlightOnActive) {
            this.closeTooltipSub = this.tooltip.isVisible$.subscribe((isOpened: boolean) => {
              if (isOpened) {
                this.applyHighlightStyle();
              } else {
                this.removeHighlightStyle();
              }
            });
          }
        } else {
          this.tooltip.show();
        }
        this.openTooltipEvent$$.next();
      });
      this.mapClickListener = this.map.addListener('click', () => {
        this.closeTooltip();
      });
    }

    if (this.options.shape.borderPolyline) {
      this.borderPolyline = new google.maps.Polyline({
        ...this.options.shape.borderPolyline,
        path: this.options.shape.borderPolyline.path ?? this.options.shape.paths,
      });
      this.borderPolyline.setMap(this.map);
    }
    if (this.options.shape.onPolygonMoved) {
      this.gPolygon.addListener('dragend', () => {
        this.options.shape.onPolygonMoved(this.gPolygon.getPath().getArray());
      });
    }

    if (this.options.shape.onPointChanged) {
      this.pointsChangedListenerSetAt = google.maps.event.addListener(this.gPolygon.getPath(), 'set_at', () => {
        this.options.shape.onPointChanged(this.gPolygon.getPath().getArray());
      });
      this.pointsChangedListenerInsertAt = google.maps.event.addListener(this.gPolygon.getPath(), 'insert_at', () => {
        this.options.shape.onPointChanged(this.gPolygon.getPath().getArray());
      });
    }
    this.gPolygon.setMap(this.map);
  }

  public remove(): void {
    google.maps.event.clearInstanceListeners(this.gPolygon);
    this.gPolygon.setMap(null);
    if (this.polygonMarker) {
      this.polygonMarker.setMap(null);
    }
    if (this.tooltip) {
      this.tooltip.remove();
    }
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
    if (this.pointsChangedListenerSetAt) {
      this.pointsChangedListenerSetAt.remove();
    }
    if (this.pointsChangedListenerInsertAt) {
      this.pointsChangedListenerInsertAt.remove();
    }
    if (this.borderPolyline) {
      this.borderPolyline.setMap(null);
    }
    if (this.closeTooltipSub) {
      this.closeTooltipSub.unsubscribe();
    }
  }

  public closeTooltip(): void {
    if (this.tooltip) {
      this.tooltip.hide();
    }
  }

  private getPolygonCenter() {
    return getPolygonCenter(this.gPolygon);
  }

  private applyHighlightStyle(): void {
    if (this.options.shape.borderPolyline) {
      if (this.options.shape.borderPolyline.icons?.length) {
        this.borderPolyline.set(
          'icons',
          this.options.shape.borderPolyline.icons.map((iconSequence) => {
            return {
              ...iconSequence,
              icon: {
                ...iconSequence.icon,
                strokeWeight: 3,
              },
            };
          }),
        );
      } else {
        this.borderPolyline.set('strokeWeight', 4);
      }
    } else {
      this.gPolygon.set('strokeWeight', 4);
    }
  }

  private removeHighlightStyle() {
    if (this.options.shape.borderPolyline) {
      if (this.options.shape.borderPolyline.icons?.length) {
        this.borderPolyline.set('icons', this.options.shape.borderPolyline.icons);
      } else {
        this.borderPolyline.set('strokeWeight', this.options.shape.borderPolyline.strokeWeight);
      }
    } else {
      this.gPolygon.set('strokeWeight', this.options.shape.strokeWeight);
    }
  }
}
