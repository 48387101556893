import { Injectable } from '@angular/core';
import {
  loadAnnotatedRideTraceError,
  loadAnnotatedRideTraceRequested,
  loadAnnotatedRideTraceResponse,
} from '../actions/rides-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../../../api/api.service';
import { of } from 'rxjs';
import { WpError } from '@rootTypes';
import { Store } from '@ngrx/store';

@Injectable()
export class RideAnnotatedTracesEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store,
  ) {}

  public loadAnnotatedRideTraceRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAnnotatedRideTraceRequested),
      mergeMap(({ request }) => {
        return this.api.getAnnotatedTracesForRide(request).pipe(
          map((response) => loadAnnotatedRideTraceResponse({ rideId: request.rideId, response })),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load annotated ride trace',
              retryFn: () => this.store.dispatch(loadAnnotatedRideTraceRequested({ request })),
            };
            return of(
              loadAnnotatedRideTraceError({
                rideId: request.rideId,
                error,
              }),
            );
          }),
        );
      }),
    ),
  );
}
