import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GetDefaultDistrictGradesResponse } from '../endpoints/get-default-district-grades';

@Injectable()
export class DefaultDistrictGradesApiMockService {
  public getDefaultDistrictGrades(): Observable<GetDefaultDistrictGradesResponse> {
    return of({
      standardGrades: [
        {
          gradeId: '1',
          value: '1',
          isCustom: false,
        },
        {
          gradeId: '2',
          value: '2',
          isCustom: false,
        },
      ],
    });
  }
}
