import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/ride-data-flash.actions';
import { catchError, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as fromTypes from '../../../flash/types';
import { PortalRouteTracePoint, WpError } from '../../../flash/types';
import * as fromRideDataActions from '../actions/rides-data.actions';
import {
  getRidePolylineRequested,
  getRideV2RequestedOnFlashUpdate,
  routeTraceError,
} from '../actions/rides-data.actions';
import { Observable } from 'rxjs/internal/Observable';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { selectRideV2Status } from '../selectors/ride-data-v2.selectors';
import { Injectable } from '@angular/core';
import { FlashApiService } from '../../../../api/flash-api.service';
import { flashUpdateError } from '../../../flash';
import { RideV2ProjectionKey } from '@apiEntities/rides/ride-v2-projection';
import { RideApiService } from '../../../../api/services/ride-api.service';

@Injectable()
export class RideDataFlashEffects {
  constructor(
    private flashApi: FlashApiService,
    private api: RideApiService,
    private actions$: Actions,
    private store: Store,
  ) {}

  /**
   * Ride details
   */
  public subscribeRideDetailsPrimary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.subscribeRideDetailsPrimary),
      switchMap((action) => {
        const { rideIds, projections } = action;
        return this.onSubscribeRideDetails(rideIds, fromTypes.FlashUpdateOutlet.PRIMARY, projections).pipe(
          takeUntil(this.actions$.pipe(ofType(fromActions.unsubscribeRideDetailsPrimary))),
        );
      }),
    ),
  );

  public subscribeRideDetailsSecondary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.subscribeRideDetailsSecondary),
      switchMap((action) => {
        const { rideIds, projections } = action;
        return this.onSubscribeRideDetails(rideIds, fromTypes.FlashUpdateOutlet.SECONDARY, projections).pipe(
          takeUntil(this.actions$.pipe(ofType(fromActions.unsubscribeRideDetailsSecondary))),
        );
      }),
    ),
  );

  /**
   * Route traces
   */
  public subscribeRouteTraces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.subscribeRouteTraces),
      tap((action) => this.store.dispatch(fromRideDataActions.resetRouteTrace({ rideId: action.rideId }))),
      switchMap((action) => {
        const { rideId } = action;
        return this.onSubscribeRouteTraces(rideId).pipe(
          takeUntil(this.actions$.pipe(ofType(fromActions.unsubscribeRouteTraces))),
        );
      }),
    ),
  );

  public flashRouteTraceReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.flashUpdateRouteTraces),
      map((action) => {
        const { rideId, result } = action;
        return fromRideDataActions.incomingRouteTracesReceived({ rideId, routeTraces: result });
      }),
    ),
  );

  /**
   * Helpers
   */

  private onSubscribeRideDetails(
    rideIds: string[],
    outletName: fromTypes.FlashUpdateOutlet,
    projections: RideV2ProjectionKey[],
  ): Observable<Action> {
    const obs =
      outletName === fromTypes.FlashUpdateOutlet.PRIMARY
        ? this.flashApi.subscribeToRidesPrimary(rideIds)
        : this.flashApi.subscribeToRidesSecondary(rideIds);
    return obs.pipe(
      filter((source) => !!source?.result.rideId),
      map((source) => {
        const rideId = source.rideId;
        return getRideV2RequestedOnFlashUpdate({ request: { rideId, _projections: projections } });
      }),
      catchError((err) => {
        return of(
          flashUpdateError({
            error: {
              text: `Error in flash update stream (ride details, ${outletName})`,
              originalError: err,
            },
          }),
        );
      }),
    );
  }

  public onSubscribeRouteTraces(rideId: string): Observable<Action> {
    return this.store.select(selectRideV2Status(rideId)).pipe(
      distinctUntilChanged(),
      switchMap((rideStatus) => {
        switch (rideStatus) {
          case null:
            return of(fromActions.subscribeRouteTracesIdle({ rideId, status: rideStatus }));
          case 'SCHEDULED':
          case 'CANCELLED': {
            this.store.dispatch(getRidePolylineRequested({ rideId }));
            return of(fromActions.subscribeRouteTracesIdle({ rideId, status: rideStatus }));
          }
          case 'DRIVER_ENROUTE': {
            return this.getInitialRouteTraceForEnRouteRide(rideId).pipe(
              switchMap(({ routeTraces, lastIndex }) => {
                this.store.dispatch(
                  fromRideDataActions.routeTraceHistoryReceived({
                    rideId,
                    routeTraces,
                    lastIndex,
                  }),
                );
                return this.getFlashRouteTraceUpdateStream$(rideId);
              }),
              catchError((originalError) => {
                const error: WpError = {
                  originalError,
                  text: 'Failed to load ride traces',
                };
                return of(routeTraceError({ rideId, error }));
              }),
            );
          }
          case 'IN_PROGRESS': {
            return this.getInitialRouteTraceForInProgressOrCompletedRide(rideId).pipe(
              switchMap(({ routeTraces, lastIndex }) => {
                this.store.dispatch(
                  fromRideDataActions.routeTraceHistoryReceived({
                    rideId,
                    routeTraces,
                    lastIndex,
                  }),
                );
                return this.getFlashRouteTraceUpdateStream$(rideId);
              }),
              catchError((originalError) => {
                const error: WpError = {
                  originalError,
                  text: 'Failed to load ride traces',
                };
                return of(routeTraceError({ rideId, error }));
              }),
            );
          }
          case 'COMPLETED': {
            return this.getInitialRouteTraceForInProgressOrCompletedRide(rideId).pipe(
              switchMap(({ routeTraces, lastIndex }) => {
                return of(
                  fromRideDataActions.routeTraceHistoryReceived({
                    rideId,
                    routeTraces,
                    lastIndex,
                  }),
                );
              }),
              catchError((originalError) => {
                const error: WpError = {
                  originalError,
                  text: 'Failed to load ride traces',
                };
                return of(routeTraceError({ rideId, error }));
              }),
            );
          }
          default: {
            console.warn(`Unknown ride status: ${rideStatus}`);
            return of(fromActions.subscribeRouteTracesIdle({ rideId, status: rideStatus }));
          }
        }
      }),
    );
  }

  private getFlashRouteTraceUpdateStream$(rideId: string): Observable<Action> {
    return this.flashApi.subscribeToRideRouteTrace(rideId).pipe(
      map((source) => fromActions.flashUpdateRouteTraces({ rideId, result: source.traces })),
      catchError((err) => {
        return of(
          flashUpdateError({
            error: {
              text: 'Error in flash update stream (route traces)',
              originalError: err,
            },
          }),
        );
      }),
    );
  }

  private getInitialRouteTraceForEnRouteRide(
    rideId: string,
  ): Observable<{ routeTraces: PortalRouteTracePoint[]; lastIndex: number }> {
    return this.api.getRouteTracesForRideId({ rideId }).pipe(
      map((resp) => {
        const { traces, lastIndex } = resp;
        // Try to get two last route traces if available,
        // to orient the car properly
        const lastRouteTrace = traces?.length ? traces[traces.length - 1] : undefined;
        const pointBeforeLast = traces.length > 1 ? traces[traces.length - 2] : undefined;
        const routeTraces = pointBeforeLast
          ? [pointBeforeLast, lastRouteTrace]
          : lastRouteTrace
            ? [lastRouteTrace]
            : [];
        return {
          routeTraces,
          lastIndex,
        };
      }),
    );
  }

  private getInitialRouteTraceForInProgressOrCompletedRide(
    rideId: string,
  ): Observable<{ routeTraces: PortalRouteTracePoint[]; lastIndex: number }> {
    return this.api
      .getRouteTracesForRideId({
        rideId,
      })
      .pipe(
        map((resp) => {
          const { traces, lastIndex } = resp;
          return {
            routeTraces: traces || [],
            lastIndex,
          };
        }),
      );
  }
}
