import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wp-link-download',
  templateUrl: './link-download.component.html',
  styleUrl: './link-download.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkDownloadComponent {
  @Input() href: string;
  @Input() openInNewTab = false;
  @Input() isBold = false;
}
