import { EntityStatus, PortalEntityType } from '@rootTypes';
import { entitySearchConfig } from '@rootTypes/utils/portal-entity/entity-search-config';
import {
  EntityFilterOptions,
  EntityFilterRequest,
  EntityFilterRequestType,
  EntityFilterStatus,
} from '../../../api/endpoints/entity-filter';

export const getEntityFilterRequest = (
  query: string,
  type: PortalEntityType,
  status: EntityStatus,
  skip: number,
  limit: number,
  options: EntityFilterOptions = {},
): EntityFilterRequest => {
  return {
    query,
    type: entitySearchConfig[type].entityFilterRequestType || (type as unknown as EntityFilterRequestType),
    status: portalEntityStatusToEntityFilterStatus(status),
    skip,
    limit,
    ...options,
  };
};

export const portalEntityStatusToEntityFilterStatus = (status?: EntityStatus): EntityFilterStatus | undefined => {
  if (!status || status === EntityStatus.ANY) {
    return undefined;
  }

  return status === EntityStatus.ACTIVE ? EntityFilterStatus.ACTIVE : EntityFilterStatus.INACTIVE;
};
